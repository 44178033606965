import { makeStyles, createStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) =>
  createStyles({
    staticSum_number: {
      color: '#e64147',
      fontSize: '2.5rem',
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.875rem'
      }
    },
    staticSum_title: {
      fontSize: 16,
      fontWeight: 500,
      color: '#000'
    },
    wrap: {
      padding: '0 30px',
      marginTop: 28,
      borderRight: '1px solid #9E9E9E'
    },
    wrap_last: {
      paddingLeft: 30,
      paddingRight: 15,
      marginTop: 28
    }
  })
)
