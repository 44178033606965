import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useMainSliderStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 1920,
      width: '100%',
      margin: '0 auto',
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 60,
      paddingBottom: 60,

      [theme.breakpoints.down('sm')]: {
        paddingTop: 30,
        paddingBottom: 30,

        '& .swiper-button-prev': {
          display: 'block'
        },

        '& .swiper-button-next': {
          display: 'block'
        }
      },

      [theme.breakpoints.down('xs')]: {
        '& .swiper-button-prev': {
          display: 'none'
        },

        '& .swiper-button-next': {
          display: 'none'
        }
      }
    },

    wrap: {
      display: 'flex',
      justifyContent: 'center'
    },

    mainTitle: {
      marginBottom: 64,
      textAlign: 'center',

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.7rem',
        marginBottom: 32
      }
    },

    block: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 512,
      width: '100%',
      height: 'auto',
      minHeight: 408,
      marginBottom: 64
      // backgroundColor: '#eee'
    },

    body: {
      flexShrink: 0,
      height: '100%',
      marginBottom: 'auto'
    },

    title: {
      marginBottom: 16,
      fontWeight: 600
    },

    text: {
      marginBottom: 16
    },

    footer: {
      display: 'flex',
      justifyContent: 'space-between'
    },

    icon: {},

    info: {
      display: 'flex',
      alignItems: 'center'
    },

    logo: {
      marginRight: 20
    },

    nameWrap: {},

    name: {
      marginBottom: 8
    },

    position: {
      color: '#9E9E9E'
    }
  })
)
