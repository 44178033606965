import React, { FC, useRef } from 'react'
import { Hero } from './hero/hero'
import { NewServices } from './services/services'
import { NoTime } from './no-time/no-time'
import { WhereHelps } from './where-helps/where-helps'
import { NewSeoText } from './new-seo-text/new-seo-text'
import { SupportForm } from './support-form/support-form'
import { Reviews } from './reviews/reviews'
import { ServicesBig } from './services-big/services-big'

export const RedesignedLanding: FC = () => {
  const helperRef = useRef<null | HTMLDivElement>(null)
  return (
    <>
      <Hero formRef={helperRef} />
      <NewServices />
      <ServicesBig />
      <WhereHelps />
      <NoTime formRef={helperRef} />
      <Reviews />
      <NewSeoText />
      <SupportForm formRef={helperRef} />
    </>
  )
}
