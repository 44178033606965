/* eslint-disable max-len */
import { makeStyles, createStyles } from '@material-ui/core'

export const useMarketCardStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flex: '0 1 100%',
      maxWidth: 350,
      width: '100%',
      height: 515,
      overflow: 'visible',
      backgroundColor: '#fff',
      borderRadius: '4px 4px 9px 9px',
      position: 'relative',
      paddingBottom: 20,

      '&:hover $menu': {
        visibility: 'visible',
        transition: theme.transitions.create('visibility', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.complex
        })
      }
    },

    header_publication: {
      float: 'left',
      fontSize: 12,
      color: '#828282',

      [theme.breakpoints.down(1440)]: {
        width: '100%'
      }
    },

    iconDiv: {
      display: 'flex',

      [theme.breakpoints.down(1600)]: {
        width: '100%'
      }
    },

    footerInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: '10px',
      alignItems: 'center',
      [theme.breakpoints.down(1600)]: {
        flexDirection: 'column',
        alignItems: 'start',
        gap: 12
      }
    },

    priceInfo: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      marginBottom: '13.5px',

      [theme.breakpoints.down(1440)]: {
        flexDirection: 'column'
      },
      [theme.breakpoints.down(600)]: {
        marginBottom: '10px'
      }
    },

    modelPrice: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0em',
      color: '#2E86BD'
    },

    modelArea: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '17px',
      letterSpacing: '0em',
      color: '#666666'
    },

    cardFavoritIcon: {},

    favoritButton: {
      fontSize: 12,
      display: 'flex',
      alignItems: 'center'
    },

    favoritText: {
      marginTop: 4
    },

    favoritIcon: {
      color: 'white'
    },
    premium: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      padding: '5px 9px',
      backgroundColor: '#F2C94C',
      borderTopLeftRadius: 4,
      borderBottomRightRadius: 4,
      zIndex: 2,
      [theme.breakpoints.down('xs')]: {
        padding: '1px 3px 1px 5px'
      }
    },
    premiumTitle: {
      color: '#fff',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 12,
      [theme.breakpoints.down('xs')]: {
        fontSize: 10
      }
    },

    info: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'start',
      width: '100%',

      [theme.breakpoints.down(600)]: {
        marginTop: 12
      }
    },

    infoBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginTop: 25,
      paddingLeft: 15
    },

    infoBottomMobile: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 12,
      paddingRight: 20,
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },

    infoCard: {},
    content: {
      height: '100%',
      padding: theme.spacing(1),
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down(600)]: {
        padding: 'px 5px'
      }
    },

    name: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: '10px'
    },

    iconName: {
      position: 'relative'
    },

    crown: {
      zIndex: 999,
      '& *': {
        fill: '#fff'
      }
    },

    crownMobile: {
      zIndex: 999
    },

    fullNameDiv: {
      width: '100%',
      marginTop: 2,
      color: 'black',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '40px',
      marginBottom: '10px'
    },

    fullName: {
      fontWeight: 500,
      color: 'black',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: '10px'
    },

    img: {
      objectFit: 'cover',
      width: '100%',
      height: 200,
      display: 'block'
    },

    imageDiv: {
      marginBottom: 20
    },

    discription: {
      fontSize: 14,
      textAlign: 'start',
      wordWrap: 'break-word',
      marginBottom: '13.5px',
      height: '40px',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      [theme.breakpoints.down(600)]: {
        marginBottom: '10px'
      }
    },

    iconWrap: {
      display: 'flex',
      alignItems: 'center'
    },

    projectName: {
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      margin: '0 0 20px',
      height: '50px',

      [theme.breakpoints.down(1440)]: {
        margin: '0 0px 6px'
      }
    },

    projectNameTitle: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: 16,
      textAlign: 'start',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },

    projectNameSim: {},
    actions: {
      alignItems: 'center'
    },

    cardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '0px 32px 0 14px',
      color: '#757575',
      ['@media (max-width: 1370px)']: {
        padding: '0px 8px 0 6px'
      },
      [theme.breakpoints.down('md')]: {
        padding: '0px 8px 0 6px',
        gap: 2
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0px 32px 0 14px',
        gap: 0
      }
    },

    headerYeys: {
      display: 'flex',
      color: '#2E86BD',
      alignItems: 'center',
      marginLeft: '12px',

      [theme.breakpoints.down(1440)]: {
        marginLeft: '0px'
      }
    },

    headerDate: {
      color: '#2E86BD',
      marginLeft: 5
    },

    viewdNumber: {
      marginLeft: 8,
      fontSize: 14
    },

    downloadCountNumber: {
      fontSize: 14,
      marginLeft: 8
    },

    menu: {
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center'
      }
    },
    favorit: {
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-start'
      }
    },

    favoriteIconDiv: {
      width: '35px',
      height: '35px',
      borderBottomLeftRadius: '4px',
      backgroundColor: '#fff',
      position: 'absolute',
      top: 8,
      right: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    mobileName: {
      display: 'flex',
      // padding: '0 20px',
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },

    mobileBlock: {
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },

    descriptionMobile: {
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },

    descriptionDiv: {
      display: 'block'
    },

    infoMobile: {
      width: '100%'
    },

    buttonPosition: {
      display: 'flex',
      justifyContent: 'center'
    },

    button: {
      width: '100%',
      padding: '10px 22px',
      fontSize: '14px',
      marginTop: '15px',
      fontWeight: 500,
      borderRadius: '9px',
      textTransform: 'none'
    },

    hoverContent: {
      opacity: 0,
      position: 'absolute',
      bottom: 0,
      left: 0,
      padding: '8px 8px 20px 8px',
      zIndex: 100,
      backgroundColor: '#fff',
      borderTop: 'none',
      borderRadius: '0 0 9px 9px',
      transition: '.3s'
    },

    hoverContentVisible: {
      opacity: 1,
      bottom: '-112px'
    },

    avatarUrl: {
      height: '32px',
      width: '32px',
      borderRadius: '50%'
    },

    modelFlagIcon: {
      position: 'absolute',
      left: 0
    },

    modelFlagText: {
      position: 'absolute',
      left: 5,
      zIndex: 999,
      color: 'white',
      fontSize: '12px'
    },

    modalFlagIconDiv: {
      position: 'absolute',
      left: 0,
      top: 18
    },

    nameBlock: {
      alignSelf: 'flex-start',
      height: 45,
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginBottom: 20,
      fontSize: 16,
      fontWeight: 500
    },

    cost: {
      fontSize: 16,
      fontWeight: 600,
      color: 'rgba(46, 134, 189, 1)'
    },

    dateBlock: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: 10
    },

    fullNameProjects: {
      width: '100%',
      marginLeft: 10,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',

      [theme.breakpoints.down(1440)]: {
        marginBottom: theme.spacing(1)
      }
    },

    infoProjects: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      margin: '5px 0',

      [theme.breakpoints.down(600)]: {
        marginTop: 12
      }
    }
  })
)
