import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useStatsStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingTop: 64,
      paddingBottom: 80,
      marginTop: 60,
      marginBottom: 60,
      backgroundColor: '#EDF0F1',

      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },

    wrap: {
      maxWidth: 1600,
      width: '100%',
      margin: '0 auto',
      paddingLeft: 16,
      paddingRight: 16,
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',

      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center'
      }
    },

    title: {
      marginBottom: 64,
      textAlign: 'center'
    },

    block: {
      maxWidth: 493,
      width: '100%',

      [theme.breakpoints.down('lg')]: {
        margin: '0 20px 64px'
      }
    },

    num: {
      fontWeight: 900,
      textAlign: 'center',
      marginBottom: 16
    },

    name: {
      textAlign: 'center'
    }
  })
)
