import { makeStyles, createStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '108px 74px 135px',
      display: 'flex',
      position: 'relative',
      backgroundColor: '#fff',
      backgroundImage: 'url("/assets/img-bg.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0 80%',
      [theme.breakpoints.down('lg')]: {
        padding: '77px 115px 132px',
        backgroundPosition: '10% 15%'
      },
      [theme.breakpoints.down('md')]: {
        padding: '83px 15px 62px 10px',
        backgroundImage: 'none'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '70px 10px 74px 10px'
      }
    },
    imageWrap: {
      position: 'relative',
      borderRadius: 10,
      height: '100%',
      [theme.breakpoints.down('xl')]: {
        marginRight: 70
      },
      [theme.breakpoints.down('lg')]: {
        marginRight: 0,
        marginBottom: 70
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10
      }
    },
    img: {
      borderRadius: 10,
      objectFit: 'cover',
      height: '100%',
      width: '100%'
    },
    info_description: {
      fontSize: '1.125rem'
    },
    pointsWrap: {
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      borderRadius: 20,
      backgroundColor: '#fff',
      height: 140,
      display: 'flex',
      [theme.breakpoints.down('lg')]: {
        marginTop: 50
      },
      [theme.breakpoints.down('md')]: {
        marginRight: '25%',
        marginTop: 20,
        height: 277
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        marginTop: 30
      }
    },
    info: {
      justifyContent: 'space-between',
      backgroundColor: '#fff',
      [theme.breakpoints.down('lg')]: {
        marginRight: '15%'
      },
      [theme.breakpoints.down('md')]: {
        marginRight: '30%'
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 0
      }
    }
  })
)
