import { makeStyles, createStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingTop: 76,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 92
      },
      '& h4': {
        marginBottom: 15,
        [theme.breakpoints.down('md')]: {
          marginBottom: 10
        },
        [theme.breakpoints.down('xs')]: {
          marginBottom: 15,
          marginTop: 11
        }
      }
    },
    office: {
      height: '100%',
      backgroundImage: 'url("/assets/big-nav/office-1920.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom right',
      backgroundSize: 'contain',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      padding: '0 0 67px 73px',
      transition: '0.3s',
      [theme.breakpoints.down('lg')]: {
        padding: '0 0 50px 73px',
        backgroundImage: 'url("/assets/big-nav/office-1400.png")'
      },
      [theme.breakpoints.down('sm')]: {
        backgroundImage: 'url("/assets/big-nav/office-768.png")',
        justifyContent: 'flex-start',
        padding: '25px 19px 0 50px',
        '& h4': {
          marginTop: 26
        }
      },
      [theme.breakpoints.down('xs')]: {
        backgroundImage: 'url("/assets/big-nav/office-375.png")',
        padding: '23px 0 0 21px',
        height: 255,
        borderBottom: 0
      }
      // '&:hover': {
      //   backgroundColor: '#fff'
      // },
      // '&:active': {
      //   backgroundColor: '#F8F8F8'
      // }
    },
    market: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      padding: '0 0 67px 73px',
      backgroundImage: 'url("/assets/big-nav/market-1920.png")',
      borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
      backgroundPosition: 'bottom right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      transition: '0.3s',
      [theme.breakpoints.down('lg')]: {
        padding: '0 0 50px 73px',
        backgroundImage: 'url("/assets/big-nav/market-1400.png")'
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        padding: '25px 19px 0 50px',
        borderRight: '1px solid rgba(0, 0, 0, 0.05)',
        borderBottom: 'none',
        height: 501,
        backgroundImage: 'url("/assets/big-nav/market-768.png")',
        backgroundPosition: 'bottom right'
      },
      [theme.breakpoints.down('xs')]: {
        backgroundImage: 'url("/assets/big-nav/market-375.png")',
        height: 255,
        borderRight: 0,
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        padding: '23px 0 0 21px'
      }
      // '&:hover': {
      //   backgroundColor: '#fff'
      // },
      // '&:active': {
      //   backgroundColor: '#F8F8F8'
      // }
    },
    ads: {
      height: 616,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      padding: '0 0 67px 73px',
      cursor: 'pointer',
      transition: '0.3s',
      borderRight: '1px solid rgba(0, 0, 0, 0.05)',
      backgroundImage: 'url("/assets/big-nav/ads-1920.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom right',
      backgroundSize: 'contain',
      [theme.breakpoints.down('lg')]: {
        padding: '0 0 50px 73px'
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        borderRight: 0,
        padding: '50px 0 0 50px',
        height: 437
      },
      [theme.breakpoints.down('xs')]: {
        backgroundImage: 'url("/assets/big-nav/ads-375.png")',
        height: 255,
        padding: '20px 0 35px 21px'
      },
      '&:hover': {
        backgroundColor: '#fff'
      },
      '&:active': {
        backgroundColor: '#F8F8F8'
      }
    },
    adsEng: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      padding: '0 0 67px 73px',
      cursor: 'pointer',
      transition: '0.3s',
      backgroundImage: 'url("/assets/big-nav/ads-en-1920.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom right',
      backgroundSize: 'contain',
      [theme.breakpoints.down('lg')]: {
        padding: '0 0 50px 73px'
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        padding: '22px 0 0 14px',
        height: 501
      },
      [theme.breakpoints.down('xs')]: {
        backgroundImage: 'url("/assets/big-nav/ads-en-375.png")',
        height: 255,
        padding: '44px 0 35px 21px'
      },
      '&:hover': {
        backgroundColor: '#fff'
      },
      '&:active': {
        backgroundColor: '#F8F8F8'
      }
    },
    gradient: {
      position: 'absolute',
      left: 0,
      top: '10%',
      width: '60%',
      height: '100%',
      borderRadius: 20,
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      backdropFilter: 'blur(7px)',
      zIndex: 66,
      [theme.breakpoints.down(600)]: {
        width: '82%'
      },
      [theme.breakpoints.down(385)]: {
        width: '95%'
      }
    },
    gradientShop: {
      position: 'absolute',
      left: 0,
      top: '10%',
      width: '110%',
      height: '100%',
      borderRadius: 20,
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      backdropFilter: 'blur(7px)',
      zIndex: 66,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    gradientOffice: {
      position: 'absolute',
      left: -15,
      top: '10%',
      width: '92%',
      height: '100%',
      borderRadius: 20,
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      backdropFilter: 'blur(7px)',
      zIndex: 66,
      [theme.breakpoints.down('xs')]: {
        width: '110%'
      }
    },
    titleHeading: {
      position: 'relative',
      width: 'fit-content',
      marginTop: 20,
      marginBottom: 10,
      zIndex: 666,
      [theme.breakpoints.down('lg')]: {
        fontSize: 28
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 22
      }
    },
    row: {
      position: 'relative',
      maxWidth: 400,
      fontWeight: 500,
      zIndex: 666,
      [theme.breakpoints.down('lg')]: {
        maxWidth: 300
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 12
      }
    },
    row_office: {
      position: 'relative',
      maxWidth: 300,
      fontWeight: 500,
      zIndex: 666,
      [theme.breakpoints.down('lg')]: {
        maxWidth: 300
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 12
      }
    },
    row_long: {
      position: 'relative',
      maxWidth: 450,
      fontWeight: 500,
      zIndex: 666,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
        maxWidth: 320
      }
    },
    block: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 30,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 20,
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    blockJob: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 30,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 20
      }
    },
    text: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 30,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0
      }
    },
    textJob: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column'
    },

    btnBlock: {
      display: 'flex'
    },

    btn: {
      padding: '8px 0',
      width: 146,
      marginBottom: 0,
      marginRight: 20,
      borderRadius: 9,
      textTransform: 'none',
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10
      }
    }
  })
)
