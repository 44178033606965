import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useWhatsStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 1600,
      width: '100%',
      margin: '0 auto',
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 60,
      paddingBottom: 60,

      [theme.breakpoints.down('sm')]: {
        paddingTop: 30,
        paddingBottom: 30
      }
    },

    wrap: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    },

    bigRoot: {
      paddingTop: 60,
      paddingBottom: 60,

      [theme.breakpoints.down('sm')]: {
        paddingTop: 30,
        paddingBottom: 30,
        maxWidth: 1600,
        width: '100%',
        margin: '0 auto',
        paddingLeft: 16,
        paddingRight: 16
      }
    },

    bigWrap: {
      maxWidth: 1600,
      width: '100%',
      margin: '0 auto',
      paddingLeft: 16,
      paddingRight: 16,
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    },

    bigVideoWrap: {
      maxWidth: 1920,
      width: '100%',
      margin: '0 auto',
      marginBottom: 120,
      position: 'relative',
      paddingBottom: '56.25%',
      paddingTop: 25,
      height: 0,

      [theme.breakpoints.down('sm')]: {
        marginBottom: 40
      }
    },

    bigVideo: {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    },

    title: {
      maxWidth: 1000,
      width: '100%',
      margin: '0 auto 32px',
      marginBottom: 32,
      textAlign: 'center',

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.7rem'
      }
    },

    subTitle: {
      maxWidth: 824,
      width: '100%',
      margin: '0 auto 72px',
      textAlign: 'center',

      [theme.breakpoints.down('sm')]: {
        textAlign: 'left'
      }
    },

    info: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 152,

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'center'
      },

      [theme.breakpoints.down('sm')]: {
        marginBottom: 60
      }
    },

    infoReverse: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',

      '& $infoContent': {
        marginRight: 0,
        marginLeft: 192,

        [theme.breakpoints.down('md')]: {
          marginLeft: 0,
          marginBottom: 64
        },

        [theme.breakpoints.down('sm')]: {
          marginBottom: 32
        }
      },

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'center'
      }
    },

    infoContent: {
      maxWidth: 608,
      width: '100%',
      marginRight: 192,

      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        marginBottom: 64
      },

      [theme.breakpoints.down('sm')]: {
        marginBottom: 32
      }
    },

    infoTitle: {
      marginBottom: 24
    },

    infoText: {
      marginBottom: 32
    },

    infoBtn: {},

    infoVideoWrap: {
      width: '100%',
      boxShadow: '8px 8px 16px rgba(98, 98, 98, 0.5)'
    },

    infoVideo: {
      display: 'block',
      width: 768,
      height: 432,

      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: 'auto'
      }
    }
  })
)
