import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useReviewsStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '48px',
      maxWidth: '1360px',
      padding: '112px 0',
      [theme.breakpoints.down(1440)]: {
        padding: '112px 8px'
      },
      [theme.breakpoints.down(900)]: {
        padding: '56px 8px',
        gap: '24px'
      },
      [theme.breakpoints.down(600)]: {
        padding: '56px 16px'
      }
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      [theme.breakpoints.down(900)]: {
        gap: '16px'
      }
    },
    title: {
      fontSize: '46px',
      fontWeight: 600,
      [theme.breakpoints.down(900)]: {
        fontSize: '26px'
      }
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    description: {
      fontSize: '24px',
      fontWeight: 500,
      [theme.breakpoints.down(900)]: {
        fontSize: '16px',
        lineHeight: '22px'
      }
    },
    prevButton: {
      transform: 'rotate(180deg)'
    },
    controlButton: {
      padding: '4px'
    },
    controlButtonContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    cardWrapper: {
      height: 'auto'
    },
    card: {
      padding: '32px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '24px',
      height: 356,
      boxShadow: 'none',
      border: '1px solid #EEEEEC',
      borderRadius: '8px',
      [theme.breakpoints.down(900)]: {
        padding: '24px',
        height: 406
      }
    },
    cardContent: {
      padding: 0,
      flex: '1 1 100%'
    },
    cardText: {
      fontSize: '16px',
      lineHeight: '24px'
    },
    cardFooter: {
      padding: 0
    },
    cardReviewerName: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: 'normal'
    },
    cardReviewerPosition: {
      maxWidth: 260,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 'normal'
    },
    cardReviewerImage: {
      width: '64px',
      height: '64px',
      borderRadius: '50%'
    }
  })
)
