import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useWhereHelpsStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      maxWidth: '1360px',
      padding: '112px 0 60px',
      [theme.breakpoints.down(1200)]: {
        padding: '56px 32px 24px'
      }
    },
    title: {
      fontSize: '46px',
      fontWeight: 600,
      lineHeight: 'normal',
      [theme.breakpoints.down(900)]: {
        fontSize: '26px'
      }
    },
    description: {
      paddingTop: '24px',
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '36px',
      [theme.breakpoints.down(900)]: {
        paddingTop: '16px',
        lineHeight: '22px',
        fontSize: '16px'
      }
    },
    columnsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      // flexWrap: 'wrap'
      [theme.breakpoints.down(600)]: {
        flexWrap: 'wrap'
      }
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',
      [theme.breakpoints.up(600)]: {
        '&:nth-child(2)': {
          paddingLeft: '5%',
          borderLeft: '1px solid #EEEEEC'
        },
        '&:nth-child(1)': {
          paddingRight: '5%'
        }
      }
    },
    columnHeading: {
      fontSize: '28px',
      lineHeight: 'normal',
      fontWeight: 600,
      [theme.breakpoints.down(900)]: {
        fontSize: '20px'
      }
    },
    columnItem: {
      flex: '1 1 50%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '36px',
      '& div:has(>img)': {
        flex: '1 0 auto'
      }
    },
    columnText: {
      display: 'block',
      flex: '1 1 auto',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      [theme.breakpoints.down(900)]: {
        fontSize: '15px'
      }
    }
  })
)
