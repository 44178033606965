import React, { FC } from 'react'
import {
  Container,
  Grid,
  Typography,
  Button,
  Card,
  Link as MaterialLink,
  Link,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
// import Link from 'next/link'
import { useServicesStyles } from './services.styles'
import Image from 'next/image'
import clsx from 'clsx'
// import { useRouter } from 'next/router'
import { useLocale, useStaticTranslate, SERVICES_URL } from '@plandi/common'

export const NewServices: FC = () => {
  const classes = useServicesStyles()
  // const router = useRouter()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(900))
  const locale = useLocale()
  const url = SERVICES_URL
  const t = useStaticTranslate()
  return (
    <Container className={classes.root}>
      <Typography className={classes.title}>{t('our-services')}</Typography>
      <Typography className={classes.description}>
        {t('services-description')}
      </Typography>
      <Grid container spacing={isMobile ? 3 : 7} alignItems={'stretch'}>
        <Grid item sm md={4}>
          <Card className={classes.cardRoot}>
            <Typography className={classes.cardTitle}>{t('job')}</Typography>
            <Typography className={classes.cardDescription}>
              {t('job-description')}
            </Typography>
            <ul className={classes.cardList}>
              <li className={classes.carListItem}>
                <Link
                  href={
                    locale === 'ru'
                      ? `${url.job}/ru/applicants`
                      : `${url.job}/applicants`
                  }
                >
                  <MaterialLink className={classes.cardLink}>
                    {t('project-vacancies')}
                  </MaterialLink>
                </Link>
              </li>
              <li className={classes.carListItem}>
                <Link
                  href={
                    locale === 'ru'
                      ? `${url.job}/ru/employers`
                      : `${url.job}/employers`
                  }
                >
                  <MaterialLink className={classes.cardLink}>
                    {t('sepcialist-base')}
                  </MaterialLink>
                </Link>
              </li>
              <li className={classes.carListItem}>
                <Link
                  href={
                    locale === 'ru'
                      ? `${url.job}/ru/companies`
                      : `${url.job}/companies`
                  }
                >
                  <MaterialLink className={classes.cardLink}>
                    {t('companies')}
                  </MaterialLink>
                </Link>
              </li>
              <li className={classes.carListItem}>
                <Link
                  href={
                    locale === 'ru'
                      ? `${url.job}/ru/factories`
                      : `${url.job}/factories`
                  }
                >
                  <MaterialLink className={classes.cardLink}>
                    {t('factories')}
                  </MaterialLink>
                </Link>
              </li>
            </ul>
            <div className={classes.cardActions}>
              <Button
                variant={'contained'}
                color={'secondary'}
                classes={{
                  root: classes.cardButton,
                  label: classes.cardButtonText
                }}
                // onClick={() => {
                //   router.push(locale === 'ru' ? `${url.job}/ru` : url.job)
                // }}
              >
                <Link
                  color="inherit"
                  underline="none"
                  href={locale === 'ru' ? `${url.job}/ru` : url.job}
                >
                  {t('get-started')}
                </Link>
              </Button>
            </div>
            <div className={classes.cardImage}>
              <Image
                src={'/assets/new-landing/jobIcon.svg'}
                width={isMobile ? 72 : 88}
                height={isMobile ? 66 : 80}
                alt={'Job'}
              />
            </div>
          </Card>
        </Grid>
        <Grid item sm md={4}>
          <Card className={classes.cardRoot}>
            <Typography className={classes.cardTitle}>{t('market')}</Typography>
            <Typography className={classes.cardDescription}>
              {t('market-description')}
            </Typography>
            <ul className={classes.cardList}>
              <li className={classes.carListItem}>
                <Link
                  href={
                    locale === 'ru'
                      ? `${url.shop}/ru/projects`
                      : `${url.shop}/projects`
                  }
                >
                  <MaterialLink className={classes.cardLink}>
                    {t('ready-projects')}
                  </MaterialLink>
                </Link>
              </li>
              <li className={classes.carListItem}>
                <Link
                  href={
                    locale === 'ru'
                      ? `${url.shop}/ru/models`
                      : `${url.shop}/models`
                  }
                >
                  <MaterialLink className={classes.cardLink}>
                    {t('bim-model-base')}
                  </MaterialLink>
                </Link>
              </li>
              <li className={classes.carListItem}>
                <Link
                  color="inherit"
                  underline="none"
                  href={
                    locale === 'ru'
                      ? `${url.shop}/ru/goods`
                      : `${url.shop}/goods`
                  }
                >
                  <MaterialLink className={classes.cardLink}>
                    {t('equipment')}
                  </MaterialLink>
                </Link>
              </li>
            </ul>
            <div className={classes.cardActions}>
              <Button
                variant={'contained'}
                color={'secondary'}
                classes={{
                  root: classes.cardButton,
                  label: classes.cardButtonText
                }}
                // onClick={() =>
                //   router.push(locale === 'ru' ? `${url.shop}/ru` : url.shop)
                // }
              >
                <Link
                  color="inherit"
                  underline="none"
                  href={locale === 'ru' ? `${url.shop}/ru` : url.shop}
                >
                  {t('get-started')}
                </Link>
              </Button>
            </div>
            <div className={classes.cardImage}>
              <Image
                src={'/assets/new-landing/marketIcon.svg'}
                width={isMobile ? 72 : 88}
                height={isMobile ? 66 : 80}
                alt={'market'}
              />
            </div>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card className={clsx(classes.cardRoot, classes.cardLongText)}>
            <Typography className={classes.cardTitle}>{t('office')}</Typography>
            <Typography className={classes.cardDescription}>
              {t('office-description')}
            </Typography>
            <div className={classes.cardActions}>
              <Button
                variant={'contained'}
                color={'secondary'}
                classes={{
                  root: classes.cardButton,
                  label: classes.cardButtonText
                }}
                // onClick={() =>
                //   router.push(
                //     `https://${
                //       process.env.NEXT_PUBLIC_NODE_ENV === 'stage'
                //         ? 'stage-office'
                //         : 'office'
                //     }.plandi.io${locale === 'ru' ? '/ru' : ''}`
                //   )
                // }
              >
                <Link
                  color="inherit"
                  underline="none"
                  href={`https://${
                    process.env.NEXT_PUBLIC_NODE_ENV === 'stage'
                      ? 'stage-office'
                      : 'office'
                  }.plandi.io${locale === 'ru' ? '/ru' : ''}`}
                >
                  {t('get-started')}
                </Link>
              </Button>
            </div>
            <div className={classes.cardImage}>
              <Image
                src={'/assets/new-landing/officeIcon.svg'}
                width={isMobile ? 72 : 88}
                height={isMobile ? 66 : 80}
                alt={'Office'}
              />
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
