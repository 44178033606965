import { makeStyles, createStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) =>
  createStyles({
    nav: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 30,
      marginLeft: -12,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 10
      }
    },
    navNotButtons: {
      marginTop: 48,
      [theme.breakpoints.down('lg')]: {
        marginBottom: 18
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0
      }
    },
    nav_wrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 420,
      height: 80,
      [theme.breakpoints.down('sm')]: {
        width: 320
      }
    },
    bullet: {
      width: '100%',
      height: 4,
      background: '#d9d9d9',
      borderRadius: 10,
      margin: '0 9px',
      opacity: 1,
      cursor: 'pointer'
    },
    bulletActive: {
      opacity: 1,
      backgroundColor: '#2E86BD !important'
    },
    btn: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    switchButtonLeft: {
      transform: 'rotate(180deg)'
    },

    bulletWrapperCustom: {
      display: 'flex',
      flexGrow: 1
    },
    link: {
      boxShadow: 'none',
      borderRadius: 9,
      padding: '10px 14px',
      height: 44,
      zIndex: 999,
      '& a': {
        textDecoration: 'none',
        color: '#E64147',
        textTransform: 'none'
      }
    },
    linkAdverts: {
      boxShadow: 'none',
      borderRadius: 9,
      padding: '10px 36px',
      height: 44,
      textTransform: 'none',

      [theme.breakpoints.down('md')]: {
        alignSelf: 'start'
      },
      '& a': {
        textDecoration: 'none',
        color: '#E64147',
        textTransform: 'none'
      }
    },
    linkBlog: {
      boxShadow: 'none',
      borderRadius: 9,
      padding: '10px 36px',
      height: 44,
      textTransform: 'none',

      [theme.breakpoints.down('md')]: {
        alignSelf: 'start'
      },
      '& a': {
        textDecoration: 'none',
        color: '#FFF',
        textTransform: 'none'
      }
    },
    arrow: {
      marginLeft: 10
    }
  })
)
