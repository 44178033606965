import React from 'react'
export const NewSliderBase = (props) => {
  return (
    <svg
      width="712"
      height="636"
      viewBox="0 0 712 636"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.4"
        d="M0 16C0 7.16343 7.16344 0 16 0H688C696.837 0 704 7.16344 704 16V636H0V16Z"
        fill="#EEEEEC"
      />
      <path
        opacity="0.6"
        d="M8 24C8 15.1634 15.1634 8 24 8H696C704.837 8 712 15.1634 712 24V636H8V24Z"
        fill="#EEEEEC"
      />
    </svg>
  )
}
