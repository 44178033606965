import React, { FC, useRef } from 'react'
import { NewSliderBase } from '../../../../jsx-ico/new-slider-base'
import Image from 'next/image'
import classes from './services-slider.module.scss'
import clsx from 'clsx'

interface ServicesSliderProps {
  currSlide: number
  items?: string[]
}

export const ServicesSlider: FC<ServicesSliderProps> = ({
  currSlide,
  items
}) => {
  const slidesRef = useRef<HTMLDivElement | undefined>()

  return (
    <div className={classes.root}>
      <div className={classes.sliderWrapper}>
        <div>
          <NewSliderBase className={classes.sliderBase} />
        </div>
        <div className={classes.sliderItems} ref={slidesRef}>
          {items?.map((item, index) => (
            <div
              className={clsx(classes.sliderItem, {
                [classes.sliderItemHidden]: currSlide !== index
              })}
              key={index}
              data-index={index}
            >
              <Image
                src={item}
                width={704}
                height={614 + 32}
                alt={'slide-image'}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
