import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useIntegrationStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: '0 auto',
      paddingTop: 60,
      paddingBottom: 60,

      [theme.breakpoints.down('sm')]: {
        paddingTop: 30,
        paddingBottom: 30
      }
    },

    wrap: {
      padding: '48px 64px',
      background: '#333333',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      },

      [theme.breakpoints.down('sm')]: {
        padding: '24px 32px'
      }
    },

    group: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: 1568,
      width: '100%',
      margin: '0 auto',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      }
    },

    title: {
      maxWidth: 850,
      width: '100%',
      color: '#ffffff',

      [theme.breakpoints.down('md')]: {
        marginBottom: 40
      }
    },

    form: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',

      [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
      },

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },

    btn: {
      '&:first-child': {
        marginRight: 32
      },

      [theme.breakpoints.down('sm')]: {
        marginBottom: 24,
        width: '100%',

        '&:first-child': {
          marginRight: 0
        }
      }
    }
  })
)
