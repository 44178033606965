import React, { FC, MutableRefObject } from 'react'
import { Container, Grid, Typography, Button } from '@material-ui/core'
import { useHeroStyles } from './hero.styles'
// import { useRouter } from 'next/router'
import {
  getAuthSignInUrl,
  // getServiceURL,
  SERVICES_URL,
  useLocale,
  useQueryUser,
  useStaticTranslate
} from '@plandi/common'
import Image from 'next/image'

interface HeroProps {
  formRef?: MutableRefObject<null | HTMLDivElement>
}

export const Hero: FC<HeroProps> = ({ formRef }) => {
  const classes = useHeroStyles()
  // const router = useRouter()
  const locale = useLocale()
  const url = SERVICES_URL
  const { data: user } = useQueryUser()
  const t = useStaticTranslate()
  return (
    <Container className={classes.root} maxWidth={false}>
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography className={classes.title} component={'h1'}>
              <Typography component={'span'} color={'secondary'}>
                PlanDi
              </Typography>{' '}
              {t('hero-heading')}
            </Typography>
            <Typography className={classes.description}>
              {t('hero-description')}
            </Typography>
            <div className={classes.buttonsWrapper}>
              <Button
                variant={'contained'}
                color={'secondary'}
                classes={{ root: classes.button, label: classes.buttonText }}
                onClick={() => {
                  if (!user) {
                    window.location.href = getAuthSignInUrl(
                      locale,
                      locale === 'ru' ? `${url.main}/ru` : url.main
                    )
                    return
                  }
                  window.location.href =
                    locale === 'ru'
                      ? `${url.job}/ru/applicants`
                      : `${url.job}/applicants`
                }}
              >
                {t('get-started')}
              </Button>

              <Button
                variant={'outlined'}
                classes={{
                  root: classes.button,
                  label: classes.buttonText
                }}
                onClick={() => {
                  formRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  })
                }}
              >
                {locale === 'ru' ? 'Узнать больше' : 'To learn more'}
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.imageContainer}>
              <Image src={'/assets/hero.svg'} width={660} height={590} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}
