import { createStyles, makeStyles } from '@material-ui/core'

export const useManagerFormStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      position: 'relative'
    },
    root: {
      position: 'relative',
      padding: '.5rem 1rem',
      backgroundColor: '#fff',
      borderRadius: 10,
      zIndex: 10,
      boxShadow: '0px 4px 25px 0px rgba(0, 0, 0, 0.05)',
      margin: '60px 80px 0 80px',
      [theme.breakpoints.down(1920)]: {
        margin: '40px 115px 0 115px'
      },
      [theme.breakpoints.down(1240)]: {
        margin: '20px 10px 0 10px'
      }
    },
    inner: {
      gap: '5%',
      padding: '25px 5%',
      [theme.breakpoints.down(768)]: {
        padding: 0
      }
    },
    textContent: {
      alignSelf: 'stretch'
    },
    heading: {
      fontSize: '1.7rem',
      [theme.breakpoints.down(768)]: {
        fontSize: '22px'
      }
    },
    description: {
      fontSize: '1.25rem',
      color: 'rgba(0,0,0,0.7)',
      [theme.breakpoints.down(768)]: {
        fontSize: '16px'
      }
    },
    buttonLabel: {
      textTransform: 'none'
    },
    or: {
      position: 'absolute',
      left: 10,
      top: 93,
      color: 'rgba(0, 0, 0, 0.54)',
      fontWeight: 500,
      [theme.breakpoints.down(768)]: {
        top: 60
      }
    }
  })
)
