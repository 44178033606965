import { makeStyles, createStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: '#FBFBFB',
      padding: '40px 80px 0 80px',
      [theme.breakpoints.down(1920)]: {
        padding: '40px 115px 0 115px'
      },
      [theme.breakpoints.down(1240)]: {
        padding: '40px 10px 0 10px'
      }
    },
    rootLoading: {
      height: 'auto',
      backgroundColor: '#fff',
      padding: '40px 80px 0 80px',
      [theme.breakpoints.down(1920)]: {
        padding: '40px 115px 0 115px'
      },
      [theme.breakpoints.down(1240)]: {
        padding: '40px 10px 0 10px'
      }
    },
    selected: {
      color: '#E52428'
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '640px',
      [theme.breakpoints.down('xs')]: {
        height: '540px'
      }
    },
    btn: {
      textTransform: 'none',
      textAlign: 'left',
      fontSize: '1.25rem',
      maxWidth: '100%',
      [theme.breakpoints.down('lg')]: {
        fontSize: 16
      }
    },
    wrapper: {
      alignItems: 'flex-start'
    },
    indicator: {
      left: 0,
      right: 'auto',
      width: 6,
      borderRadius: 10
    },
    controls: {
      height: 'auto',

      [theme.breakpoints.down('md')]: {
        marginBottom: 40
      }
    },
    chips: {
      maxWidth: 300,
      [theme.breakpoints.between(1240, 1400)]: {
        maxWidth: 250
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 330,
        marginBottom: 30
      }
    },
    link: {
      boxShadow: 'none',
      borderRadius: 9,
      padding: '10px 14px',
      height: 44,

      [theme.breakpoints.down('md')]: {
        alignSelf: 'start'
      },
      '& a': {
        textDecoration: 'none',
        color: '#E64147',
        textTransform: 'none'
      }
    },
    arrow: {
      marginLeft: 10
    }
  })
)
