import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { components } from '@plandi/common/src/generated'
import { sharedApi } from '../api'
import { SEO_TEXTS } from '../keys'
import { useLocale } from '@plandi/common'

export const useQuerySeoTexts = (
  options?: UseQueryOptions<
    components['schemas']['SEOTextResource'],
    AxiosError
  >
) => {
  const locale = useLocale()
  const data = useQuery(
    [SEO_TEXTS, locale],
    () => sharedApi.getSEOText(locale),
    {
      staleTime: 60 * 1000,
      ...options
    }
  )

  return data
}
