import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useBestLoginStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 1920,
      width: '100%',
      margin: '0 auto',
      marginBottom: '-13px',
      paddingLeft: 156,
      paddingRight: 161,
      paddingTop: 174,
      paddingBottom: 80,
      display: 'flex',
      backgroundImage: 'url(/assets/footerBG.png)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      // backgroundPosition: 'center',
      backgroundColor: '#fff',
      //backgroundSize: '100% 100%',
      justifyContent: 'space-between',
      alignItems: 'center',

      ['@media (max-width: 1502px)']: {
        paddingLeft: 80,
        paddingRight: 80
      },

      ['@media (max-width: 1280px)']: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '-100px',
        paddingTop: 200,
        paddingBottom: 126,
        backgroundPosition: 'center'
      },
      ['@media (max-width: 600px)']: {
        backgroundImage: 'url(/assets/back-Mobile-registration.png)',
        paddingBottom: 136
      }
    },

    wrap: {
      display: 'flex',
      justifyContent: 'space-between',
      ['@media (max-width: 600px)']: {
        flexDirection: 'column'
      }
    },

    title: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      textAlign: 'left',
      maxWidth: 543,
      fontSize: 20,

      ['@media (max-width: 1280px)']: {
        fontSize: 20,
        marginBottom: 40,
        minWidth: 550,
        textAlign: 'center'
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 0
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 32,
        fontSize: 24
      }
    },

    btn: {
      '&:first-child': {
        marginRight: 45,
        height: 44,
        width: 182,
        backgroundColor: '#E64147',
        borderRadius: 9,
        ['@media (max-width: 1005px)']: {
          marginBottom: 40
        }
      },
      '&:last-child': {
        color: '#212121',
        height: 44,
        width: 182
      },
      [theme.breakpoints.down(600)]: {
        '&:first-child': {
          marginRight: 0
        }
      }
    },

    logo: {
      fontWeight: 600
    },

    bestEco: {
      ['@media (max-width: 520px)']: {
        fontSize: 20
      }
    },

    logoDi: {
      color: 'hsl(0,75%,60%)'
    },

    filledBtn: {
      background: '#E64147'
    },

    outlinedBtn: {
      backgroundColor: 'transparent',
      border: '2px solid #E64147',
      borderRadius: 9
    }
  })
)
