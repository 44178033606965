import { makeStyles, createStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: 110,
      marginBottom: 110,
      [theme.breakpoints.down('sm')]: {
        marginTop: 100,
        marginBottom: 100
      }
    },

    wrap: {
      height: 188
    },

    top: {
      [theme.breakpoints.down(1024)]: {
        marginTop: 40,
        marginBottom: 40
      },
      [theme.breakpoints.down(600)]: {
        marginTop: 45,
        marginBottom: 45
      }
    },

    bottom: {
      margin: 0,
      paddingBottom: 30,
      backgroundColor: '#fff',
      [theme.breakpoints.down(1280)]: {
        paddingTop: 45
      },
      [theme.breakpoints.down(768)]: {
        paddingTop: 0
      }
    }
  })
)
