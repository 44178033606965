import { makeStyles, createStyles } from '@material-ui/core/styles'

export const usePromoOfferStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 1600,
      width: '100%',
      margin: '0 auto',
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 60,
      paddingBottom: 60,

      [theme.breakpoints.down('sm')]: {
        paddingTop: 30
      }
    },

    wrap: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    },

    info: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'center'
      }
    },

    infoContent: {
      maxWidth: 768,
      width: '100%',
      marginLeft: 32,

      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        marginBottom: 64
      },

      [theme.breakpoints.down('sm')]: {
        marginBottom: 0
      }
    },

    infoTitle: {
      marginBottom: 24
    },

    infoText: {
      marginBottom: 40
    },

    btn: {
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },

    infoImageWrap: {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        marginBottom: 32,
        display: 'flex',
        justifyContent: 'center'
      }
    },

    infoImage: {
      display: 'block',
      width: 768,
      height: 358,

      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: 'auto'
      }
    }

    // title: {
    //   maxWidth: 608,
    //   width: '100%',
    //   fontWeight: 500,
    //   fontSize: 36,
    //   lineHeight: '120%',
    //   letterSpacing: '1px',
    //   color: '#ffffff',
    //   marginBottom: 0,
    //   marginTop: 0,
    //   marginRight: 30,
    //
    //   [theme.breakpoints.down('md')]: {
    //     fontSize: 22
    //   },
    //
    //   [theme.breakpoints.down('sm')]: {
    //     marginRight: 0,
    //     marginBottom: 24
    //   },
    //
    //   [theme.breakpoints.down('xs')]: {
    //     fontSize: 18
    //   }
    // },

    // input: {
    //   maxWidth: 448,
    //   width: '100%',
    //   backgroundColor: '#ffffff',
    //
    //   '& label.Mui-focused': {
    //     color: '#acaaaa',
    //     padding: '2px 6px',
    //     backgroundColor: '#ffffff'
    //   },
    //   '& .MuiInput-underline:after': {
    //     borderBottomColor: '#ffffff'
    //   },
    //   '& .MuiOutlinedInput-root': {
    //     '& fieldset': {
    //       borderColor: '#ffffff'
    //     },
    //     '&:hover fieldset': {
    //       borderColor: '#ffffff'
    //     },
    //     '&.Mui-focused fieldset': {
    //       borderColor: '#ffffff'
    //     }
    //   },

    //   [theme.breakpoints.down('sm')]: {
    //     maxWidth: '100%'
    //   }
    // },
  })
)
