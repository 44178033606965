import React from 'react'
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useMainSliderStyles } from './review.styles'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as QuoteImg } from 'public/assets/new-landing/review/quote.svg'
import Image from 'next/image'

export type ReviewItem = {
  title: string
  text: string
  avatar: string
  name: string
  position: string
}

type ReviewProps = {
  items: ReviewItem[]
}

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export const Review: React.FC<ReviewProps> = (props) => {
  const classes = useMainSliderStyles()
  return (
    <div className={classes.root}>
      <Typography className={classes.mainTitle} variant="h4">
        Отзывы
      </Typography>
      <Swiper
        spaceBetween={50}
        slidesPerView={3}
        navigation
        pagination={{ clickable: true }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 16
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 32
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 56
          }
        }}
      >
        {props.items.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={classes.block}>
              <div className={classes.body}>
                <Typography className={classes.title} variant="h5">
                  {item.title}
                </Typography>
                <Typography className={classes.text} variant="body1">
                  {item.text}
                </Typography>
              </div>
              <div className={classes.footer}>
                <div className={classes.info}>
                  <div className={classes.logo}>
                    <Image width={64} height={64} src={item.avatar} alt="1" />
                  </div>
                  <div className={classes.nameWrap}>
                    <Typography className={classes.name} variant="h6">
                      {item.name}
                    </Typography>
                    <Typography
                      className={classes.position}
                      variant="subtitle2"
                    >
                      {item.position}
                    </Typography>
                  </div>
                </div>
                <div className={classes.icon}>
                  <QuoteImg />
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
