import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useGoToStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 1600,
      width: '100%',
      margin: '0 auto',
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 60,
      paddingBottom: 60
    },

    wrap: {
      display: 'flex',
      justifyContent: 'center'
    },

    title: {
      marginBottom: 32,
      textAlign: 'center'
    },

    btn: {
      '&:first-child': {
        marginRight: 16,

        [theme.breakpoints.down('sm')]: {
          marginRight: 0
        }
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  })
)
