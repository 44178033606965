import React, { FC, MutableRefObject } from 'react'
import Image from 'next/image'
import {
  Container,
  Typography,
  // CardContent,
  Button
  // Card,
} from '@material-ui/core'
import { useNoTimeStyles } from './no-time.styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useStaticTranslate } from '@plandi/common'

interface NoTimeProps {
  formRef?: MutableRefObject<null | HTMLDivElement>
}

export const NoTime: FC<NoTimeProps> = ({ formRef }) => {
  const classes = useNoTimeStyles()
  const theme = useTheme()
  const t = useStaticTranslate()
  const isSmall = useMediaQuery(theme.breakpoints.down(900))
  return (
    <Container className={classes.container}>
      <div className={classes.card}>
        <div className={classes.cardContent}>
          <div className={classes.textCol}>
            <Typography className={classes.heading}>{t('no-time')}</Typography>
            <Typography className={classes.description}>
              {t('our-manages')}
            </Typography>
            <Button
              variant={'contained'}
              color={'secondary'}
              classes={{
                root: classes.cardButtonRoot,
                label: classes.cardButtonLabel
              }}
              fullWidth={isSmall}
              onClick={() => {
                formRef?.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                })
              }}
            >
              {t('need-help')}
            </Button>
          </div>
          <div className={classes.imageCol}>
            <div className={classes.imageContainer}>
              <Image
                src={'/assets/new-landing/no-time-new.svg'}
                width={440}
                height={348}
                className={classes.cardImage}
                alt={'Техническая поддержка'}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
