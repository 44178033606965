import React, { FC, useCallback, useMemo, useState } from 'react'
import Container from '@material-ui/core/Container'

import { useServicesBigStyles } from './services-big.styles'
import { ServicesSlider } from './services-slider/services-slider'
import { CircularProgress, Grid } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { useNewServicesSliderQuery } from '../redesigned-landing.query'
import { Swiper, SwiperSlide } from 'swiper/react'
import { default as SwiperClass } from 'swiper/types/swiper-class'
import { useRouter } from 'next/router'
import { SERVICES_URL } from '@plandi/common'

import { ServicesTextItem } from './services-slider/services-text-item'

export const ServicesBig: FC = () => {
  const classes = useServicesBigStyles()
  const { locale } = useRouter()
  const url = SERVICES_URL
  const [swiperInstance, setSwiperInstance] = useState<null | SwiperClass>(null)
  const { data: sliderItems, isLoading: isSliderItemsLoading } =
    useNewServicesSliderQuery()
  const [currSlide, setCurrSlide] = useState(0)
  const items = useMemo(() => {
    if (!sliderItems) {
      return ['/assets/Screen.png']
    }
    return [
      sliderItems?.job?.imageUrl,
      sliderItems?.shop?.imageUrl,
      sliderItems?.office?.imageUrl,
      sliderItems?.blog?.imageUrl
    ]
  }, [sliderItems])

  const handleNextSlide = useCallback(() => {
    if (!swiperInstance) return
    swiperInstance.slideNext()
    if (currSlide >= items.length - 1) {
      setCurrSlide(0)
      return
    }
    setCurrSlide(currSlide + 1)
  }, [currSlide, items.length, swiperInstance])
  const handlePrevSlide = useCallback(() => {
    if (!swiperInstance) return
    swiperInstance.slidePrev()
    if (currSlide <= 0) {
      setCurrSlide(items.length - 1)
      return
    }
    setCurrSlide(currSlide - 1)
  }, [currSlide, items, swiperInstance])

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down(1250))
  return (
    <Container maxWidth={false} className={classes.root}>
      <Grid container spacing={5}>
        {!isSmall && (
          <Grid item xs={12} sm md={6}>
            <ServicesSlider currSlide={currSlide} items={items} />
          </Grid>
        )}
        <Grid item xs={12} sm md={6}>
          {isSliderItemsLoading ? (
            <CircularProgress />
          ) : (
            <Swiper
              className={classes.textSliderWrapper}
              spaceBetween={32}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              loop
            >
              {sliderItems?.job && (
                <SwiperSlide className={classes.sliderTextWrapper}>
                  <ServicesTextItem
                    sliderItem={sliderItems.job}
                    handlePrevSlide={handlePrevSlide}
                    handleNextSlide={handleNextSlide}
                    link={locale === 'ru' ? `${url.job}/ru` : url.job}
                  />
                </SwiperSlide>
              )}
              {sliderItems?.shop && (
                <SwiperSlide className={classes.sliderTextWrapper}>
                  <ServicesTextItem
                    sliderItem={sliderItems.shop}
                    handlePrevSlide={handlePrevSlide}
                    handleNextSlide={handleNextSlide}
                    link={locale === 'ru' ? `${url.shop}/ru` : url.shop}
                  />
                </SwiperSlide>
              )}
              {sliderItems?.office && (
                <SwiperSlide className={classes.sliderTextWrapper}>
                  <ServicesTextItem
                    sliderItem={sliderItems.office}
                    handlePrevSlide={handlePrevSlide}
                    handleNextSlide={handleNextSlide}
                    link={`https://${
                      process.env.NEXT_PUBLIC_NODE_ENV === 'stage'
                        ? 'stage-office'
                        : 'office'
                    }.plandi.io${locale === 'ru' ? '/ru' : ''}`}
                  />
                </SwiperSlide>
              )}
              {sliderItems?.blog && (
                <SwiperSlide className={classes.sliderTextWrapper}>
                  <ServicesTextItem
                    sliderItem={sliderItems.blog}
                    handlePrevSlide={handlePrevSlide}
                    handleNextSlide={handleNextSlide}
                    link={
                      locale === 'ru'
                        ? `${url.blog}/ru/articles`
                        : `${url.blog}/articles`
                    }
                  />
                </SwiperSlide>
              )}
            </Swiper>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
