import { makeStyles, createStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) =>
  createStyles({
    chip: {
      marginRight: 17,
      marginBottom: 17,
      maxWidth: '100%',
      height: 'auto',
      padding: '6px 0',
      fontSize: '0.875rem',
      '&:last-child': {
        marginRight: 17
      },
      [theme.breakpoints.down('lg')]: {
        '&:last-child': {
          marginRight: 17
        }
      },
      '& .MuiChip-root': {
        whiteSpace: 'normal'
      }
    },
    chipAdverts: {
      marginRight: 17,
      marginBottom: 0,
      maxWidth: '100%',
      height: 'auto',
      padding: '6px 0',
      fontSize: '0.875rem',
      '&:last-child': {
        marginRight: 17
      },
      [theme.breakpoints.down('lg')]: {
        marginBottom: 17,
        '&:last-child': {
          marginRight: 17
        }
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: 17
      }
    },
    chipOff: {
      display: 'none'
    },
    chipLabel: {
      textWrap: 'nowrap',
      whiteSpace: 'normal'
    }
  })
)
