import { useQuery, UseQueryOptions } from 'react-query'
import { newLandingApi } from '../new-landing/common'
import { components } from '@plandi/common/src/generated'
import { AxiosError } from 'axios'
import { useLocale } from '@plandi/common'

export const useNewServicesSliderQuery = (
  options?: UseQueryOptions<
    components['schemas']['NewServicesSliderResource'],
    AxiosError,
    components['schemas']['NewServicesSliderResource']
  >
) => {
  const locale = useLocale()
  const query = useQuery(
    ['SERVICES_SLIDER', locale],
    () => newLandingApi.getNewServicesSliderData(locale),
    options
  )
  return query
}
