import React, { FC, useCallback, useMemo, useState } from 'react'
import Container from '@material-ui/core/Container'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ReviewSliderCard } from './review-slider-card'
import { default as SwiperClass } from 'swiper/types/swiper-class'
import { useReviewsStyles } from './reviews.styles'
import clsx from 'clsx'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { RoundedArrowIcon } from 'src/jsx-ico'
import { theme, useStaticTranslate } from '@plandi/common'

export const Reviews: FC = () => {
  const classes = useReviewsStyles()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down(900))
  const isTablet = useMediaQuery(theme.breakpoints.down(1280))
  const t = useStaticTranslate()
  const items = useMemo<
    { image: string; text: string; position: string; name: string }[]
  >(
    () => [
      {
        image: '/assets/reviewer1.png',
        // eslint-disable-next-line max-len
        text: t('review-des-text'),
        position: t('review-des-pos'),
        name: t('review-des-name')
      },
      {
        image: '/assets/reviewer2.png',
        // eslint-disable-next-line max-len
        text: t('review-tech-text'),
        position: t('review-tech-pos'),
        name: t('review-tech-name')
      },
      {
        image: '/assets/reviewer3.png',
        // eslint-disable-next-line max-len
        text: t('review-stron-text'),
        position: t('review-stron-pos'),
        name: t('review-stron-name')
      },
      {
        image: '/assets/reviewer4.png',
        // eslint-disable-next-line max-len
        text: t('review-soft-text'),
        position: t('review-soft-pos'),
        name: t('review-soft-name')
      },
      {
        image: '/assets/reviewer5.png',
        // eslint-disable-next-line max-len
        text: t('review-energy-text'),
        position: t('review-energy-pos'),
        name: t('review-energy-name')
      }
    ],
    [t]
  )

  const [swiperInstace, setSwiperInstance] = useState<null | SwiperClass>(null)

  const handleNextSlide = useCallback(() => {
    if (!swiperInstace) return
    swiperInstace.slideNext()
  }, [swiperInstace])

  const handlePrevSlide = useCallback(() => {
    if (!swiperInstace) return
    swiperInstace.slidePrev()
  }, [swiperInstace])

  return (
    <Container maxWidth={false}>
      <div id={'reviews-slider'} />
      <Container className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>{t('trust')}</Typography>
            <div className={classes.controlButtonContainer}>
              <IconButton
                onClick={handlePrevSlide}
                className={clsx(classes.prevButton, classes.controlButton)}
                aria-label={'sliderControl'}
              >
                <RoundedArrowIcon
                  width={isMediumScreen ? '40px' : '48px'}
                  height={isMediumScreen ? '40px' : '48px'}
                />
              </IconButton>
              <IconButton
                onClick={handleNextSlide}
                className={classes.controlButton}
                aria-label={'sliderControl'}
              >
                <RoundedArrowIcon
                  width={isMediumScreen ? '40px' : '48px'}
                  height={isMediumScreen ? '40px' : '48px'}
                />
              </IconButton>
            </div>
          </div>
          <Typography className={classes.description}>
            {t('our-cases')}
          </Typography>
        </div>
        <Grid item>
          <Swiper
            slidesPerView={isSmallScreen ? 1 : isTablet ? 2 : 3}
            spaceBetween={56}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            loop
          >
            {items.map((item, index) => (
              <SwiperSlide key={index} className={classes.cardWrapper}>
                <ReviewSliderCard
                  text={item.text}
                  reviewerImage={item.image}
                  reviewerName={item.name}
                  reviewerPosition={item.position}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Container>
    </Container>
  )
}
