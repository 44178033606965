/* eslint-disable max-len */
import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useProfileSpecialistCardStyles = makeStyles((theme) =>
  createStyles({
    card: {
      flex: '0 1 100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '17px 0 0 0',
      borderRadius: 10,
      backgroundColor: '#FBFBFB',
      height: '100%',
      // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.down('sm')]: {
        padding: '24px 0 0'
      },
      [theme.breakpoints.down('xs')]: {
        padding: '14px 0 0'
      },
      [theme.breakpoints.down(389)]: {
        padding: '14px 0 0'
      }
    },
    cardTop: {
      display: 'flex',
      justifyContent: 'center'
    },
    premium: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      zIndex: 999,
      top: 0,
      left: 0,
      padding: '5px 9px',
      backgroundColor: '#F2C94C',
      borderTopLeftRadius: 10,
      borderBottomRightRadius: 10,
      [theme.breakpoints.down('xs')]: {
        padding: '1px 3px 1px 5px'
      }
    },
    premiumTitle: {
      color: '#fff',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 12,
      [theme.breakpoints.down('xs')]: {
        fontSize: 10
      }
    },
    cardTopOnline: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        zIndex: 2,
        display: 'block',
        width: 12,
        height: 12,
        borderRadius: '50%',
        backgroundColor: '#24C034',
        border: '4px solid #fff',
        top: '85%',
        left: '65%',
        boxSizing: 'content-box',
        [theme.breakpoints.down('xs')]: {
          top: '80%',
          left: '60%'
        }
      }
    },

    defaultImg: {
      position: 'relative',
      zIndex: 99,
      display: 'grid !important',
      placeItems: 'center',
      width: 176,
      height: 176,
      borderRadius: '50%',
      background: '#C4C4C4',
      color: '#fff',
      fontSize: 80,
      fontWeight: 500,
      // fontFamily: 'Montserrat',
      [theme.breakpoints.down('md')]: {
        width: 125,
        height: 125,
        fontSize: 60
      },
      [theme.breakpoints.down('xs')]: {
        width: 86,
        height: 86,
        fontSize: 40
      }
    },

    avatarImage: {
      position: 'relative',
      zIndex: 99,
      width: 176,
      height: 176,
      borderRadius: '50%',
      objectFit: 'cover',
      [theme.breakpoints.down('md')]: {
        width: '125px !important',
        height: '125px !important'
      },
      [theme.breakpoints.down('xs')]: {
        width: '86px !important',
        height: '86px !important'
      }
    },

    cardBody: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '13px 0 30px',
      [theme.breakpoints.down(1441)]: {
        padding: '0 10px 0 10px',
        margin: '13px 0 10px',
        maxWidth: 250
      },
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        margin: '16px 0 5px 0'
      }
    },

    name: {
      maxWidth: '95%',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 18,
      textAlign: 'center',
      color: '#212121',
      lineHeight: '21px',
      marginBottom: 8,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      [theme.breakpoints.down('sm')]: {
        height: 42,
        marginBottom: 15
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
        lineHeight: '19px',
        height: 38,
        marginBottom: 0
      }
    },

    specialization: {
      maxWidth: '95%',
      textAlign: 'center',
      fontFamily: 'Montserrat',
      fontSize: 14,
      height: 50,
      margonBottom: 13,
      lineHeight: '16px',
      color: '#757575',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('sm')]: {
        minHeight: 36
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
        minHeight: 25
      }
    },

    specializationCompanies: {
      maxWidth: '95%',
      textAlign: 'center',
      fontFamily: 'Montserrat',
      fontSize: 14,
      height: 50,
      margonBottom: 13,
      lineHeight: '16px',
      color: '#757575',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('sm')]: {
        minHeight: 36
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
        minHeight: 25
      }
    },

    reviews: {
      padding: 0,
      textAlign: 'center',
      fontFamily: 'Montserrat',
      fontWeight: 500,
      textTransform: 'none',
      fontSize: 14
    },

    reviewsCount: {
      fontWeight: 400,
      marginLeft: '5px'
    },
    rating_reviews_box: {
      display: 'flex',
      maxWidth: '255px',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 5,
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 165
      }
    },
    ratingContainer: {
      display: 'flex',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '24px'
    },
    rating: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      marginLeft: '5px',
      [theme.breakpoints.down(1441)]: {
        marginBottom: 10
      }
    },
    cardBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0 10px 10px 10px',
      [theme.breakpoints.down(1441)]: {
        flexDirection: 'column',
        gap: 15
      }
    },
    salarySum: {
      fontSize: 13,
      fontWeight: 600
    },
    salaryPeriod: {
      fontSize: 14,
      fontWeight: 400,
      color: '#00000080'
    },
    cardButton: {
      display: 'flex',
      alignItems: 'center',
      height: 44,
      width: 140,
      padding: 0,
      border: '2px solid #2E86BD',
      borderBottomRightRadius: 9,
      borderTopLeftRadius: 9,
      fontSize: 12,
      textDecoration: 'none',
      textTransform: 'none',
      color: '#333333',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        padding: '0 10px',
        height: 40
      },
      [theme.breakpoints.down(370)]: {
        fontSize: 10
      }
    },
    text: {
      margin: 0,
      padding: 0,
      height: '21px',
      fontSize: 13,
      fontWeight: 600,
      [theme.breakpoints.between(1441, 1650)]: {
        fontSize: 8
      },
      [theme.breakpoints.up(1651)]: {
        fontSize: 11
      },
      [theme.breakpoints.down(370)]: {
        fontSize: 10
      }
    }
    // salaryContainer: {
    //   flex: '1 1 auto',
    //   minHeight: '1.5rem',
    //   [theme.breakpoints.between(1239, 1480)]: {
    //     marginBottom: '2.8rem',
    //     textAlign: 'center'
    //   },
    //   [theme.breakpoints.down(960)]: {
    //     marginBottom: '2.8rem',
    //     textAlign: 'center'
    //   }
    // }
  })
)
