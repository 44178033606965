import { createStyles, makeStyles } from '@material-ui/core/styles'
import { theme } from '@plandi/common'

export const useSupportFormStyles = makeStyles<typeof theme>((theme) =>
  createStyles({
    root: {
      backgroundColor: '#F6F6F4',
      [theme.breakpoints.down(1440)]: {
        padding: '0 32px !important'
      }
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '1360px',
      padding: '112px 0',
      [theme.breakpoints.down('md')]: {
        padding: '56px 0'
      }
    },
    title: {
      fontSize: '46px',
      lineHeight: 'normal',
      fontWeight: 600,
      marginBottom: '24px',
      [theme.breakpoints.down('md')]: {
        fontSize: '26px',
        marginBottom: '16px'
      }
    },
    description: {
      fontSize: '24px',
      lineHeight: '36px',
      fontWeight: 500,
      marginBottom: '32px',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
        marginBottom: '16px',
        lineHeight: '22px'
      }
    },
    mail: {
      fontSize: '28px',
      lineHeight: 'normal',
      fontWeight: 600,
      color: '#000',
      textDecoration: 'none',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px'
      }
    },
    textBlock: {
      maxWidth: 500,
      marginBottom: '32px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '24px'
      }
    },
    input: {
      borderRadius: '8px',
      backgroundColor: '#fff',
      padding: '16px 24px',
      fontSize: '18px',
      lineHeight: 'normal',
      position: 'relative'
    },
    multiLineInput: {
      padding: 0
    },
    inputError: {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '8px'
    },
    inputIcon: {
      position: 'absolute',
      right: '12px',
      color: theme.palette.secondary.main
    },
    multiLineIcon: {
      top: '12px'
    },
    button: {
      height: '100%',
      fontSize: 16,
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        maxWidth: 400,
        fontSize: 14,
        height: '48px',
        paddingTop: 8
      }
    },
    buttonLabel: {
      textTransform: 'none'
    },
    agreement: {
      color: '#7C8584',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 'normal'
    },
    docLink: {
      color: '#7C8584'
    },
    tooltip: {
      backgroundColor: '#fff',
      color: '#7C8584',
      fontSize: '14px',
      lineHeight: 'normal',
      fontWeight: 400
    },
    thanksContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      borderRadius: '8px',
      border: '1px solid #AAACA2',
      gap: '24px',
      minHeight: '400px'
    },
    thanksTitle: {
      fontSize: '28px',
      lineHeight: 'normal',
      fontWeight: 700
    },
    thanksDescription: {
      fontSize: '18px',
      lineHeight: 'normal',
      fontWeight: 400,
      maxWidth: '280px'
    }
  })
)
