import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useNewSeoTextStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: '1360px',
      marginBottom: '112px',
      padding: 0,
      [theme.breakpoints.down(900)]: {
        padding: '0 32px',
        marginBottom: '56px'
      }
    },
    buttonLabel: {
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'none'
    }
  })
)
