import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useBlogStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 15
      }
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.up('lg')]: {
        height: 774
      }
    },

    titleContainer: {
      [theme.breakpoints.up('lg')]: {
        margin: '0 auto',
        width: 1119
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 25
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
        paddingLeft: 16
      }
    },
    title: {
      fontWeight: 600,
      fontFamily: 'Montserrat',
      fontSize: 36,
      lineHeight: '45px',
      margin: 0,
      [theme.breakpoints.up('lg')]: {
        marginLeft: 30
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: 28
      },
      [theme.breakpoints.down('md')]: {
        textAlign: 'center'
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
        fontSize: 22
      }
    },

    mySwipper: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '0 60px',

      [theme.breakpoints.up('lg')]: {
        height: 774
      },

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      },

      [theme.breakpoints.down('xs')]: {
        padding: '0 16px'
      },

      '& .swiper-wrapper': {
        marginTop: 46
      }
    },

    swiperWrapper: {
      color: 'red'
    },

    slides: {
      marginTop: 46
    },

    block: {
      display: 'flex'
    },

    content: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      gap: 56,
      position: 'relative',
      background: '#ffffff',
      borderRadius: 22,
      padding: '59px 73px 39px 93px',
      [theme.breakpoints.down('md')]: {
        padding: '23px 25px 38px 25px',
        maxWidth: 345,
        height: 477
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'auto'
      }
    },

    activeContent: {
      boxShadow: '0px 0px 54px rgba(0, 0, 0, 0.08)',
      background: '#ffffff',
      zIndex: 1000
    },

    inactiveContent: {
      opacity: '0.2',
      [theme.breakpoints.down('xs')]: {
        opacity: 0
      }
    },

    prevContent: {
      [theme.breakpoints.up('lg')]: {
        top: 255
      },

      [theme.breakpoints.down('md')]: {
        left: 120,
        bottom: 22
      },

      [theme.breakpoints.down('xs')]: {
        bottom: 'auto',
        left: 'auto'
      },

      '& .btn': {
        display: 'none'
      }
    },

    nextContent: {
      [theme.breakpoints.up('lg')]: {
        bottom: 155
      },

      [theme.breakpoints.down('md')]: {
        bottom: 25,
        left: -150
      },

      [theme.breakpoints.down('xs')]: {
        bottom: 'auto',
        left: 'auto'
      }
    },

    slideTop: {
      display: 'flex',
      gap: 27,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      }
    },

    slideLeft: {},

    slideImage: {
      width: 308,
      height: 212,
      objectFit: 'cover',
      [theme.breakpoints.down('md')]: {
        width: 257,
        height: 170
      }
    },

    slideRight: {
      display: 'flex',
      flexDirection: 'column',
      gap: 19,
      maxWidth: 610
    },

    slideTitle: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 25,
      lineHeight: '30.48px',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
        lineHeight: '21.94px',
        textAlign: 'center',
        height: 44,
        overflow: 'hidden'
      }
    },

    slideDesc: {
      fontSize: 20,
      fontFamily: 'Montserrat',
      lineHeight: '24px',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        fontSize: 15,
        lineHeight: '18.29px',
        maxWidth: 350,
        wordBreak: 'break-word',
        overflow: 'hidden'
      }
    },

    slideMetadata: {
      display: 'flex',
      gap: 55,
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
      }
    },

    metadataItem: {
      display: 'flex',
      alignItems: 'center',
      gap: 17
    },

    sliderMetadataText: {
      color: '#9E9E9E',
      fontFamily: 'Montserrat',
      lineHeight: '17px',
      fontSize: 14,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12
      }
    },

    slideBtn: {
      display: 'grid',
      placeItems: 'center',
      position: 'absolute',
      right: -20,
      bottom: -20,
      width: 79,
      height: 50,
      background: '#E64147',
      borderRadius: 9,
      [theme.breakpoints.down('xs')]: {
        right: 0
      }
    },

    bullet: {
      display: 'flex',
      flexDirection: 'column',
      height: 45,
      borderRadius: 10,
      margin: '0 0 9px',
      cursor: 'pointer',
      opacity: 1,
      background: '#d9d9d9',
      width: 4,
      [theme.breakpoints.down('md')]: {
        width: 70,
        height: 5
      }
    },

    bulletActive: {
      background: '#2E86BD'
    },

    bulletWrapperCustom: {
      width: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        flexDirection: 'row',
        width: '100%',
        gap: 9,
        marginTop: 70
      }
    },

    swipperControlWrapper: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      gap: 31,
      marginRight: 200,
      marginBottom: 100,
      [theme.breakpoints.down('lg')]: {
        marginRight: 100
      },
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        marginBottom: 0
      }
    },

    swichButtonLeft: {
      transform: 'rotate(180deg)'
    },

    swichButton: {
      width: 38,
      height: 38,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '2px solid #2E86BD',
      borderRadius: '50%',
      cursor: 'pointer',
      backgroundColor: 'white',
      color: '#2E86BD',
      outline: 'none',
      transform: 'rotate(-90deg)',

      [theme.breakpoints.down('md')]: {
        display: 'none'
      },

      '&:disabled': {
        border: '2px solid #9e9e9e',
        color: '#9e9e9e'
      }
    }
  })
)
