import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useOurServicesStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 1920,
      width: '100%',
      margin: '0 auto',
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 60,
      paddingBottom: 60,
      overflow: 'hidden',

      [theme.breakpoints.down('sm')]: {
        paddingBottom: 30
      },

      ['@media (max-width: 600px)']: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },

    wrap: {
      display: 'flex',
      flexDirection: 'column',
      gap: 61,

      [theme.breakpoints.up('lg')]: {
        padding: '0 90px'
      },

      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
        margin: '0 20px'
      },

      ['@media (max-width: 1172px)']: {
        margin: '0 60px'
      },

      [theme.breakpoints.down('sm')]: {
        margin: 0
      },

      ['@media (max-width: 720px)']: {
        margin: '0 6px'
      }
    },

    title: {
      marginBottom: 64,
      marginLeft: 140,
      textAlign: 'left',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 45,
      ['@media (max-width: 860px)']: {
        fontSize: 40
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 32
      },
      ['@media (max-width: 600px)']: {
        paddingLeft: 15,
        fontSize: 30
      },
      ['@media (max-width: 1600px)']: {
        marginLeft: 0
      }
    },

    serviceBlock: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
      boxShadow: '0px 0px 14px -7px rgba(34, 60, 80, 0.6)',
      borderRadius: 13,
      fontFamily: 'Montserrat',
      padding: '29px 59px 29px 77px',
      ['@media (max-width: 1400px)']: {
        padding: '24px 25px'
      },
      ['@media (max-width: 1172px)']: {
        padding: '24px 25px'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '13px 30px'
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        padding: '0 11px 60px 0'
      }
    },

    blockButton: {
      position: 'absolute',
      right: '-15px',
      bottom: '-20px',
      padding: '14.5px 24px',
      background: 'linear-gradient(97.4deg, #E52428 10.98%, #C40F13 70.66%)',
      borderRadius: 9,
      maxWidth: 1400,
      ['@media (max-width: 600px)']: {
        right: '10px'
      },

      '& svg': {
        color: '#fff',
        fontSize: 18
      }
    },

    serviceLeft: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'start',
        marginTop: 15,
        flex: 1.2
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0 11px'
      }
    },

    serviceTitle: {
      fontSize: 25,
      fontWeight: 600,
      color: '#D21B3C',
      ['@media (max-width: 1172px)']: {
        marginBottom: 11
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
        marginBottom: 5
      }
    },

    serviceSubtitle: {
      fontSize: 20,
      fontWeight: 500,
      maxWidth: 373,
      lineHeight: '24.38px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        lineHeight: '18px',
        fontWeight: 500,
        width: 200
      },
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        marginBottom: 25
      }
    },

    serviceRight: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        gap: 12,
        flex: 2
      },
      [theme.breakpoints.down('xs')]: {
        gap: 8
      }
    },

    serviceDetail: {
      display: 'flex',
      alignItems: 'center',
      gap: 15,
      '& p': {
        fontWeight: 500,
        fontSize: 19,
        ['@media (max-width: 811px)']: {
          fontSize: 14
        }
      },
      [theme.breakpoints.up('lg')]: {
        maxHeight: 37
      },
      ['@media (max-width: 1172px)']: {
        maxHeight: 37
      },
      ['@media (max-width: 600px)']: {
        gap: 5
      }
    },

    detail: {
      '& .MuiTypography-body1': {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '129.9%',
        fontFamily: 'Montserrat',
        [theme.breakpoints.down('sm')]: {
          fontSize: 14
        }
      }
    },

    listItem: {
      padding: 0,
      '& .MuiListItemIcon-root': {
        color: 'rgba(0, 0, 0, 0.54)',
        display: 'inline-flex',
        flexShrink: 0,
        minWidth: 0,
        [theme.breakpoints.down('xs')]: {
          // width: 25,
          // height: 25
        }
      },
      [theme.breakpoints.down('sm')]: {
        height: 30
      },
      [theme.breakpoints.down('xs')]: {
        height: 'auto'
      }
    },

    dot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 16,
      height: 16,
      borderRadius: '50%',
      boxShadow: '0px 0px 12px rgba(18, 123, 220, 0.29)'
    },
    redDot: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      background: '#D21B3C'
    }
  })
)
