import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useNoTimeStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: '1360px !important',
      marginBottom: '144px',
      padding: 0,
      [theme.breakpoints.down(900)]: {
        padding: '0 32px',
        marginBottom: '56px'
      }
    },

    card: {
      display: 'flex',
      boxShadow: 'none',
      position: 'relative',
      // border: '1px solid #EEEEEC',
      '&:before': {
        top: '10%',
        content: '" "',
        position: 'absolute',
        width: '100%',
        height: '90%',
        backgroundColor: '#f6f6f4',
        transform: 'translate(-50%, 0)',
        left: '50%',
        borderRadius: '16px'
      },
      // '& div:first-child': {
      //   flex: '1 0 480px'
      // },
      // [theme.breakpoints.down(900)]: {
      //   flexDirection: 'column',
      //   '& div:first-child': {
      //     order: '2'
      //   }
      // },
      [theme.breakpoints.up(900)]: {
        '&:before': {
          top: '20%'
        }
      }
    },

    cardImage: {
      width: '100%',
      height: 'auto',
      objectFit: 'cover'
    },
    cardContent: {
      zIndex: 20,
      padding: '48px 96px 0',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '2%',
      [theme.breakpoints.down(900)]: {
        padding: '24px'
      }
    },
    heading: {
      fontSize: '28px',
      fontWeight: 600,
      lineHeight: 'normal',
      marginTop: '48px',
      [theme.breakpoints.down(900)]: {
        marginTop: '24px',
        fontSize: '20px'
      }
    },
    description: {
      maxWidth: 700,
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '36px',
      margin: '16px 0 32px',
      [theme.breakpoints.down(900)]: {
        margin: '16px 0 24px',
        fontSize: '16px',
        lineHeight: '22px'
      }
    },
    cardButtonRoot: {
      fontSize: '16px',
      fontWeight: 600,
      width: 180,
      height: 48,
      marginTop: '16px',
      boxShadow: 'none',
      [theme.breakpoints.down(900)]: {
        alignSelf: 'center',
        fontSize: '14px',
        width: '100%',
        maxWidth: 350,
        height: 40,
        marginTop: 0
      }
    },
    cardButtonLabel: {
      textTransform: 'none'
    },
    imageCol: {
      flex: '1 1 29%',
      [theme.breakpoints.down(900)]: {
        flex: '1 1 100%',
        order: -1
      }
    },
    textCol: {
      flex: '1 1 69%'
    },
    imageContainer: {}
  })
)
