import React, { useEffect } from 'react'
import lozad from 'lozad'
import { QueryClient } from 'react-query'
import { dehydrate } from 'react-query/hydration'
import { GetServerSideProps } from 'next'
import { MainLayout } from 'src/features/layouts'
// import { NewLanding } from 'src/features/new-landing'
import {
  // BEST_SPECIALIZATIONS,
  // MAIN_PAGE_DATA,
  newLandingApi,
  TRANSLATE
} from 'src/features/new-landing/common'

// import { isMobileOnly } from 'react-device-detect'
import { mainSeo, mainSeoEn } from '@plandi/common'
import { RedesignedLanding } from '../features/redesigned-landing'
import { SEO_TEXTS, sharedApi } from 'src/shared'

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const queryClient = new QueryClient()
  await Promise.allSettled([
    // queryClient.prefetchQuery(MAIN_PAGE_DATA, newLandingApi.getMainPageData),
    // queryClient.prefetchQuery(
    //   BEST_SPECIALIZATIONS,
    //   newLandingApi.getBestSpecializations
    // ),
    queryClient.prefetchQuery([SEO_TEXTS, locale], () =>
      sharedApi.getSEOText(locale ?? 'en')
    ),
    queryClient.prefetchQuery(['SERVICES_SLIDER', locale], () =>
      newLandingApi.getNewServicesSliderData(locale ?? 'en')
    ),
    queryClient.prefetchQuery(
      TRANSLATE,
      () => newLandingApi.getTranslate(locale ?? 'en'),
      { staleTime: Infinity, cacheTime: Infinity }
    )
  ])

  return {
    props: {
      locale: locale,
      dehydratedState: dehydrate(queryClient)
    }
  }
}

const IndexPage = (props: { locale: string }) => {
  const { title, description } = mainSeo
  const { titleEn, descriptionEn } = mainSeoEn
  const lozadObserver = typeof window !== 'undefined' ? lozad() : null

  useEffect(() => {
    lozadObserver?.observe?.()
  }, [lozadObserver])

  return (
    <MainLayout
      isShowFooter
      title={props.locale === 'ru' ? title : titleEn}
      description={props.locale === 'ru' ? description : descriptionEn}
      newBg
    >
      <RedesignedLanding />
      {/*<NewLanding locale={props.locale} isMobileOnly={isMobileOnly} />*/}
    </MainLayout>
  )
}

export default IndexPage
