import { Typography, useMediaQuery, useTheme } from '@material-ui/core'

import React, { FC, useState } from 'react'
import { useQuerySeoTexts } from '../../../shared'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import { useNewSeoTextStyles } from './new-seo-text.styles'
import { useStaticTranslate } from '@plandi/common'

export const NewSeoText: FC = () => {
  const { data: seoText } = useQuerySeoTexts()
  const [isShown, setShown] = useState(false)
  const classes = useNewSeoTextStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down(900))
  const t = useStaticTranslate()

  const parsedText = React.useMemo(() => {
    if (!seoText?.main) return { text: '' }
    if (typeof window === 'undefined') {
      return { text: seoText.main }
    }
    const parser = new DOMParser()
    const doc = parser.parseFromString(seoText.main, 'text/html')
    const headings = doc.getElementsByTagName('h1')
    const paragraphs = doc.getElementsByTagName('p')
    return { headings, paragraphs, text: seoText.main }
  }, [seoText])

  return (
    <Container className={classes.root}>
      {typeof window !== 'undefined' ? (
        <div>
          {isShown ? (
            <>
              <Typography
                component={'div'}
                style={{ marginTop: '-24px' }}
                dangerouslySetInnerHTML={{ __html: parsedText?.text }}
              />
            </>
          ) : (
            <>
              <Typography
                component={'div'}
                style={{ marginTop: '-24px' }}
                dangerouslySetInnerHTML={{
                  __html: parsedText?.headings?.[0].outerHTML
                }}
              />
              <Typography
                component={'div'}
                dangerouslySetInnerHTML={{
                  __html: Array.from(parsedText?.paragraphs)?.reduce(
                    (ac, paragraph) => {
                      if (
                        ac.replaceAll(
                          /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g,
                          ''
                        ).length > 180
                      ) {
                        return ac
                      }
                      return ac + paragraph.outerHTML
                    },
                    ''
                  )
                }}
              />
            </>
          )}
          <Button
            onClick={() => setShown((shown) => !shown)}
            variant={'outlined'}
            classes={{ label: classes.buttonLabel }}
            fullWidth={isSmall}
          >
            {isShown ? t('hide') : t('see-more')}
          </Button>
        </div>
      ) : (
        <Typography
          component={'div'}
          dangerouslySetInnerHTML={{ __html: parsedText.text }}
        />
      )}
    </Container>
  )
}
