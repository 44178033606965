/* eslint-disable max-len */
import { makeStyles, createStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) =>
  createStyles({
    card: {
      flex: '0 1 100%',
      maxWidth: 420,
      width: '100%',
      padding: '24px 18px 24px 16px',
      borderRadius: 4,
      backgroundColor: '#FBFBFB',
      // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      marginBottom: 5,

      [theme.breakpoints.down('lg')]: {
        padding: '24px 16px'
      }
    },

    disable: {
      opacity: '0.4'
    },

    cardTop: {
      display: 'flex',
      flexDirection: 'column-reverse',
      marginBottom: 8,
      flexWrap: 'wrap',

      [theme.breakpoints.up('xs')]: {
        flexDirection: 'row',
        justifyContent: 'space-between'
      }
    },

    tabs: {
      marginBottom: 24
    },

    cardTopAdverts: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 8
    },

    cardTopLeftAdverts: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'row',
        marginTop: 13
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row-reverse'
      }
    },

    cardTopRight: {
      marginTop: 5,
      display: 'flex',
      justifyContent: 'space-between'
    },

    mainTitle: {
      maxWidth: 700,
      height: 60,
      fontWeight: 600,
      cursor: 'pointer',
      order: 1,
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      [theme.breakpoints.up('lg')]: {
        marginTop: 0,
        paddingRight: 16,
        order: 0
      }
    },

    nameInfo: {
      display: 'flex',
      alignItems: 'center'
    },

    verifyIcon: {
      marginLeft: 8
    },

    mainDesc: {
      marginTop: 8,
      marginBottom: 24,
      height: 80,
      display: '-webkit-box',
      '-webkit-line-clamp': 4,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },

    mainDescText: {
      color: '#666666'
    },

    groupChip: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginBottom: 24,

      '& > div': {
        overflow: 'hidden',
        marginBottom: 8,
        marginRight: 24
      }
    },

    chip: {
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },

    groupChipAdverts: {
      flexWrap: 'wrap',
      overflow: 'hidden',
      height: 160,

      '& > div': {
        overflow: 'hidden',
        marginBottom: 8,
        marginRight: 16
      }
    },

    action: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'row-reverse'
      }
    },

    price: {
      fontWeight: 600
    },

    priceAdverts: {
      marginBottom: 24,
      marginRight: 20,
      fontWeight: 600,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('lg')]: {
        marginBottom: 0
      }
    },

    tooltip: {
      margin: 0
    },

    hammerTooltip: {
      backgroundColor: '#2E86BD',
      borderRadius: '10px',
      marginLeft: '75px !important',

      [theme.breakpoints.down(960)]: {
        marginLeft: '23px !important'
      }
    },

    hammerTooltipArrow: {
      left: '10px !important',
      color: '#2E86BD'
    },

    cardBottomLeft: {
      display: 'flex',
      alignItems: 'center'
    },

    cardStats: {
      margin: '0 62px 0 50px',
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      [theme.breakpoints.down(1600)]: {
        margin: '0 40px 0 40px'
      },
      [theme.breakpoints.down('lg')]: {
        margin: 0
      }
    },

    cardStatsBlock: {
      display: 'flex',
      alignItems: 'center',
      gap: 6,
      fontSize: 14,
      color: '#2E86BD'
    },

    cardStatsIcon: {
      width: 22,
      height: 16
    },

    bottomBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  })
)
