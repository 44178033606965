import React, { FC } from 'react'
import Card from '@material-ui/core/Card'
import { Grid, Typography } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Image from 'next/image'
import { Quote } from '../../../jsx-ico'
import { useReviewsStyles } from './reviews.styles'

interface ReviewSliderCardProps {
  text: string
  reviewerName: string
  reviewerImage: string
  reviewerPosition: string
}

export const ReviewSliderCard: FC<ReviewSliderCardProps> = ({
  text,
  reviewerImage,
  reviewerName,
  reviewerPosition
}) => {
  const classes = useReviewsStyles()
  return (
    <Card className={classes.card}>
      <Quote />
      <CardContent className={classes.cardContent}>
        <Typography className={classes.cardText}>{text}</Typography>
      </CardContent>
      <CardActions className={classes.cardFooter}>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item xs={10}>
            <Typography className={classes.cardReviewerName}>
              {reviewerName}
            </Typography>
            <Typography className={classes.cardReviewerPosition}>
              {reviewerPosition}
            </Typography>
          </Grid>
          <Grid item xs>
            <Image
              src={reviewerImage}
              width={64}
              height={64}
              className={classes.cardReviewerImage}
              alt={classes.cardReviewerName}
            />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
