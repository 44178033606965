import React, { FC } from 'react'
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core'
import Image from 'next/image'
import { useWhereHelpsStyles } from './where-helps.style'
import { theme, useStaticTranslate } from '@plandi/common'

export const WhereHelps: FC = () => {
  const classes = useWhereHelpsStyles()
  const t = useStaticTranslate()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(900))

  return (
    <Container className={classes.wrapper}>
      <Grid container spacing={4}>
        <Grid item md={4}>
          <Typography className={classes.title}>{t('how-help')}</Typography>
          <Typography className={classes.description}>
            {t('everything-for-busines')}
          </Typography>
        </Grid>
        <Grid item md className={classes.columnsWrapper}>
          <div
            className={classes.column}
            style={{ marginBottom: isMobileScreen ? 32 : 0 }}
          >
            <Typography className={classes.columnHeading}>
              {t('for-specialists')}
            </Typography>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/1.svg"
                alt={`${t('for-specialists')} 1`}
              />
              <Typography className={classes.columnText}>
                {t('find-work')}
              </Typography>
            </div>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/2.svg"
                alt={'Специалистам 2'}
              />
              <Typography className={classes.columnText}>
                {t('create-portfolio')}
              </Typography>
            </div>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/3.svg"
                alt={'Специалистам 3'}
              />
              <Typography className={classes.columnText}>
                {t('download-bim')}
              </Typography>
            </div>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/4.svg"
                alt={'Специалистам 3'}
              />
              <Typography className={classes.columnText}>
                {t('projects-in-office')}
              </Typography>
            </div>
          </div>
          <div className={classes.column}>
            <Typography className={classes.columnHeading}>
              {t('for-busibes')}
            </Typography>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/5.svg"
                alt={'Специалистам 4'}
              />
              <Typography className={classes.columnText}>
                {t('select-specialist')}
              </Typography>
            </div>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/6.svg"
                alt={'Специалистам 5'}
              />
              <Typography className={classes.columnText}>
                {t('recive-projects')}
              </Typography>
            </div>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/7.svg"
                alt={'Специалистам 6'}
              />
              <Typography className={classes.columnText}>
                {t('monitor-projects')}
              </Typography>
            </div>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/8.svg"
                alt={'Специалистам 7'}
              />
              <Typography className={classes.columnText}>
                {t('find-bim')}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}
