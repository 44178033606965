/* eslint-disable max-len */
import React from 'react'
export const Quote = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="&#226;&#128;&#156;"
        d="M46.5443 0.962199C46.0039 2.24513 45.5086 3.6197 45.0583 5.08591C44.6981 6.46048 44.473 7.83505 44.3829 9.20962C44.3829 10.4926 44.6081 11.6838 45.0583 12.7835C45.5086 13.7915 46.3191 14.6163 47.4898 15.2577C49.8312 16.5407 51.8125 18.1901 53.4334 20.2062C55.1445 22.1306 56 24.5132 56 27.354C56 29.0951 55.6848 30.7446 55.0544 32.3024C54.424 33.8603 53.5235 35.2348 52.3528 36.4261C51.2721 37.5258 49.9663 38.3963 48.4354 39.0378C46.9045 39.6793 45.2835 40 43.5724 40C40.1504 40 37.2686 38.7629 34.9272 36.2887C32.5858 33.8144 31.325 30.8362 31.1449 27.354C30.8747 23.6884 31.325 20.2978 32.4957 17.1821C33.7565 13.9748 35.1973 11.1798 36.8183 8.79725C38.5294 6.41466 40.1954 4.49026 41.8164 3.02406C43.4374 1.46621 44.563 0.45819 45.1934 0C45.3735 0.091638 45.5987 0.229095 45.8688 0.412371L46.2741 0.824742C46.3641 0.91638 46.4542 0.962199 46.5443 0.962199ZM15.4754 0.962199C14.935 2.24513 14.4397 3.6197 13.9894 5.08591C13.6292 6.46048 13.4041 7.83505 13.314 9.20962C13.314 10.4926 13.5392 11.6838 13.9894 12.7835C14.4397 13.7915 15.2502 14.6163 16.4209 15.2577C18.7624 16.5407 20.7436 18.1901 22.3645 20.2062C24.0756 22.1306 24.9311 24.5132 24.9311 27.354C24.9311 29.0951 24.6159 30.7446 23.9855 32.3024C23.3551 33.8603 22.4546 35.2348 21.2839 36.4261C20.2032 37.5258 18.8974 38.3963 17.3665 39.0378C15.8356 39.6793 14.2146 40 12.5035 40C9.08146 40 6.19971 38.7629 3.85828 36.2887C1.51686 33.8144 0.256093 30.8362 0.0759837 27.354C-0.194181 23.6884 0.256093 20.2978 1.42681 17.1821C2.68757 13.9748 4.12845 11.1798 5.74943 8.79725C7.46048 6.41466 9.12649 4.49026 10.7475 3.02406C12.3685 1.46621 13.4941 0.45819 14.1245 0C14.3046 0.091638 14.5298 0.229095 14.7999 0.412371L15.2052 0.824742C15.2952 0.91638 15.3853 0.962199 15.4754 0.962199Z"
        fill="#EEEEEC"
      />
    </svg>
  )
}
