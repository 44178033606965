import React, { FC, MutableRefObject, useState } from 'react'
import {
  Container,
  Grid,
  InputBase,
  Link,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { useSupportFormStyles } from './support-form.styles'
import Button from '@material-ui/core/Button'
import { useFormik } from 'formik'
import WarningIcon from '@material-ui/icons/Warning'
import * as Yup from 'yup'
import clsx from 'clsx'
import { layoutApi } from '@plandi/common/src/components/layouts/common/layoutApi'
import { useAppDispatch } from '../../../store'
import {
  pushDangerNotification,
  theme,
  useLocale,
  useStaticTranslate
} from '@plandi/common'

interface SupportFormProps {
  formRef?: MutableRefObject<null | HTMLDivElement>
}

export const SupportForm: FC<SupportFormProps> = ({ formRef }) => {
  const classes = useSupportFormStyles()
  const dispatch = useAppDispatch()
  const [isSuccessSent, setSuccessSent] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down(900))
  const t = useStaticTranslate()
  const locale = useLocale()
  const email = locale === 'ru' ? 'support@plandi.ru' : 'support@plandi.io'

  const formik = useFormik({
    initialValues: {
      name: '',
      companyName: '',
      email: '',
      phone: '',
      message: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t('validation-required')),
      companyName: Yup.string()
        .optional()
        .max(256, t('validation-max-len').replace('%d', '256')),
      email: Yup.string()
        .required(t('validation-required'))
        .email(t('validation-email'))
        .max(256, t('validation-max-len').replace('%d', '256')),
      phone: Yup.string()
        .min(12, t('validation-phone-min'))
        .max(18, t('validation-phone-max')),
      message: Yup.string()
        .required(t('validation-required'))
        .min(15, t('validation-min-len').replace('%d', '15'))
        .max(2048, t('validation-max-len').replace('%d', '2048'))
    }),
    onSubmit: (values) => {
      layoutApi
        .getSupportData(values, locale)
        .then(() => {
          formik.resetForm()
          setSuccessSent(true)
        })
        .catch(() => {
          dispatch(pushDangerNotification(t('request-failed')))
        })
    }
  })
  return (
    <Container maxWidth={false} className={classes.root} ref={formRef}>
      <div id={'support-form'} />
      <Container className={classes.wrapper}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: isMobile ? 0 : 24
          }}
        >
          <Grid item xs={12} sm={6} md={6} className={classes.textBlock}>
            <Typography className={classes.title}>
              {t('any-questions')}
            </Typography>
            <Typography className={classes.description}>
              {t('we-answer').replace('.', ':')}
            </Typography>
            <Link href={`mailto:${email}`} className={classes.mail}>
              {email}
            </Link>
          </Grid>
          <Grid item xs md={6}>
            {isSuccessSent ? (
              <div className={classes.thanksContainer}>
                <Typography align={'center'} className={classes.thanksTitle}>
                  {t('thanks')}!
                </Typography>
                <Typography
                  align={'center'}
                  className={classes.thanksDescription}
                >
                  {t('support-form-success-text')}
                </Typography>
                <Button
                  classes={{ label: classes.buttonLabel }}
                  variant={'outlined'}
                  onClick={() => setSuccessSent(false)}
                >
                  Ок
                </Button>
              </div>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <InputBase
                      classes={{
                        input: classes.input,
                        error: classes.inputError
                      }}
                      placeholder={`* ${t('name')}`}
                      fullWidth
                      name={'name'}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={Boolean(formik.touched.name && formik.errors.name)}
                      endAdornment={
                        Boolean(formik.touched.name && formik.errors.name) && (
                          <Tooltip
                            title={formik.errors.name}
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <WarningIcon className={classes.inputIcon} />
                          </Tooltip>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <InputBase
                      classes={{
                        input: classes.input,
                        error: classes.inputError
                      }}
                      placeholder={t('company-name')}
                      fullWidth
                      name={'companyName'}
                      value={formik.values.companyName}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.companyName && formik.errors.companyName
                      )}
                      endAdornment={
                        Boolean(
                          formik.touched.companyName &&
                            formik.errors.companyName
                        ) && (
                          <Tooltip
                            title={formik.errors.companyName}
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <WarningIcon className={classes.inputIcon} />
                          </Tooltip>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <InputBase
                      classes={{
                        input: classes.input,
                        error: classes.inputError
                      }}
                      placeholder={'* E-mail'}
                      fullWidth
                      name={'email'}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.email && formik.errors.email
                      )}
                      endAdornment={
                        Boolean(
                          formik.touched.email && formik.errors.email
                        ) && (
                          <Tooltip
                            title={formik.errors.email}
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <WarningIcon className={classes.inputIcon} />
                          </Tooltip>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <InputBase
                      classes={{
                        input: classes.input,
                        error: classes.inputError
                      }}
                      placeholder={t('phone')}
                      fullWidth
                      name={'phone'}
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      inputProps={{}}
                      error={Boolean(
                        formik.touched.phone && formik.errors.phone
                      )}
                      endAdornment={
                        Boolean(
                          formik.touched.phone && formik.errors.phone
                        ) && (
                          <Tooltip
                            title={formik.errors.phone}
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <WarningIcon className={classes.inputIcon} />
                          </Tooltip>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      classes={{
                        input: classes.input,
                        error: classes.inputError,
                        multiline: classes.multiLineInput
                      }}
                      placeholder={`* ${t('message')}`}
                      style={{ paddingBottom: isMobile ? 0 : 16 }}
                      multiline
                      minRows={6}
                      fullWidth
                      name={'message'}
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.message && formik.errors.message
                      )}
                      endAdornment={
                        Boolean(
                          formik.touched.message && formik.errors.message
                        ) && (
                          <Tooltip
                            title={formik.errors.message}
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <WarningIcon
                              className={clsx(
                                classes.inputIcon,
                                classes.multiLineIcon
                              )}
                            />
                          </Tooltip>
                        )
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    className={classes.agreementSection}
                  >
                    <Typography className={classes.agreement}>
                      {locale === 'ru'
                        ? 'Отправляя сообщение я подтверждаю, что ознакомился с '
                        : 'By submitting this form I confirm that I have read the '}
                      <Link
                        underline={'always'}
                        href={
                          'https://storage.yandexcloud.net/plandi-storage/storage/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9.pdf'
                        }
                        className={classes.docLink}
                      >
                        {locale === 'ru'
                          ? 'политикой обработки персональных данных'
                          : 'personal data processing policy'}
                      </Link>
                      {locale === 'ru' ? ' и ' : ' and the '}
                      <Link
                        underline={'always'}
                        href={
                          locale === 'ru'
                            ? 'https://storage.yandexcloud.net/plandi-storage/storage/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf'
                            : 'https://plandi-storage.storage.yandexcloud.net/storage/03/03/5_Privacy_policy.pdf'
                        }
                        className={classes.docLink}
                      >
                        {locale === 'ru'
                          ? 'политикой конфиденциальности '
                          : 'privacy policy '}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md>
                    <Button
                      className={classes.button}
                      variant={'contained'}
                      fullWidth
                      color={'secondary'}
                      type={'submit'}
                      classes={{ label: classes.buttonLabel }}
                    >
                      {t('send')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}
