import { makeStyles, createStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '40px 80px 0 80px',
      [theme.breakpoints.down(1920)]: {
        margin: '40px 115px 0 115px'
      },
      [theme.breakpoints.down(1240)]: {
        margin: '40px 10px 0 10px'
      }
    },
    rootLoading: {
      height: 'auto',
      margin: '40px 80px 0 80px',
      [theme.breakpoints.down(1920)]: {
        margin: '40px 115px 0 115px'
      },
      [theme.breakpoints.down(1240)]: {
        margin: '40px 10px 0 10px'
      }
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '670px',
      [theme.breakpoints.down('xs')]: {
        height: '570px'
      }
    },
    controls: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginBottom: 70,
      [theme.breakpoints.down('lg')]: {
        height: 'auto',
        marginBottom: 50,
        alignItems: 'flex-start'
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: 40
      }
    },
    title: {
      marginRight: 100,
      verticalAlign: 'top',
      marginTop: 20,
      marginBottom: 15,
      [theme.breakpoints.down('lg')]: {
        marginBottom: 0,
        fontSize: 28
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        fontSize: 22
      }
    },
    chips: {
      [theme.breakpoints.down('lg')]: {
        marginTop: 10,
        maxWidth: 600
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: 30
      }
    },
    link: {
      boxShadow: 'none',
      borderRadius: 9,
      padding: '10px 14px',
      height: 44,

      [theme.breakpoints.down('md')]: {
        alignSelf: 'start',
        marginBottom: 20
      },
      '& a': {
        textDecoration: 'none',
        color: '#E64147',
        textTransform: 'none'
      }
    },
    arrow: {
      marginLeft: 10
    }
  })
)
