import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useWhyStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 1920,
      width: '100%',
      margin: '0 auto',
      paddingTop: 60,
      paddingBottom: 60,

      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },

    row: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridTemplateRows: 'repeat(2, 1fr)',
      rowGap: 48,
      columnGap: 32,
      width: '100%',

      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        gap: '20px',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(3, 1fr)'
      },

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr'
      }
    },

    title: {
      maxWidth: 608,
      width: '100%',
      margin: '0 auto 56px',
      textAlign: 'center',

      [theme.breakpoints.down('md')]: {
        marginBottom: 40
      }
    },

    smallBlock: {
      width: '100%',
      minWidth: 320,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '32px 16px 40px',

      [theme.breakpoints.down('sm')]: {
        gridColumn: '1/4'
      }
    },

    bigBlockOne: {
      width: '100%',
      minWidth: 320,
      display: 'flex',
      gridColumn: '3/5',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '32px 16px 40px',

      [theme.breakpoints.down('md')]: {
        gridColumn: '1/4'
      }
    },

    bigBlockTwo: {
      minWidth: 320,
      display: 'flex',
      gridColumn: '2/4',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '32px 16px 40px',

      [theme.breakpoints.down('md')]: {
        gridColumn: '1/4',
        order: 3
      }
    },

    img: {
      marginBottom: 16
    },

    blockTitle: {
      textAlign: 'center',
      fontWeight: 500,
      marginBottom: 8
    },

    desc: {
      width: '100%',
      maxWidth: 608,
      textAlign: 'center'
    }
  })
)
