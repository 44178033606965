import { makeStyles, createStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      backgroundImage: 'url("/assets/ads.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center right',
      backgroundSize: 'contain',
      height: 587,
      margin: '80px 80px 100px 80px',
      [theme.breakpoints.between(480, 1240)]: {
        backgroundImage: 'url("/assets/ads-gradient.png")'
      },
      [theme.breakpoints.down('lg')]: {
        margin: '80px 106px 110px 115px',
        height: 360
      },
      [theme.breakpoints.down('sm')]: {
        backgroundPosition: 'top center',
        margin: '80px 106px 100px 55px',
        alignItems: 'flex-end',
        height: 762
      },
      [theme.breakpoints.down('xs')]: {
        backgroundPosition: 'top center',
        margin: '30px 15px 55px 15px',
        alignItems: 'flex-end',
        height: 610
      }
    },
    text: {
      [theme.breakpoints.down('md')]: {
        maxWidth: 400
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
      }
    },
    title: {
      [theme.breakpoints.down('lg')]: {
        fontSize: 28
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 22
      }
    },
    subtitle: {
      color: '#9E9E9E',
      marginBottom: 50,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 30,
        fontSize: 18
      }
    },
    list: {
      marginBottom: 40,
      [theme.breakpoints.down('md')]: {
        marginRight: 0
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: 30
      }
    },
    listItem: {
      padding: 0,
      marginLeft: '-5px'
    },
    listItemText: {
      margin: 0
    },
    listItemTextParagraph: {
      [theme.breakpoints.down('lg')]: {
        fontSize: 18
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      }
    },
    btn: {
      boxShadow: 'none',
      borderRadius: 9,
      textTransform: 'none',
      padding: '10px 20px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.75rem'
      }
    }
  })
)
