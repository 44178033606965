import React from 'react'
export const RoundedArrowIcon = (props) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Button-Next">
        <circle
          id="Base"
          opacity="0.8"
          cx="24"
          cy="24"
          r="23.5"
          stroke="#4A3D3C"
        />
        <path
          id="Vector"
          // eslint-disable-next-line max-len
          d="M25.6799 14L24.3548 15.284L32.4128 23.0921H12V24.9079H32.4128L24.3548 32.716L25.6799 34L36 24L25.6799 14Z"
          fill="#4A3D3C"
        />
      </g>
    </svg>
  )
}
